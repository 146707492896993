import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const BtnView = (props) => {
    return (      
            <StyledButton variant="primary" onClick={props.onClick} disabled={props.disabled}>
                <div className="d-flex">
                <div className="icon-btn">{props.icon}</div>
                <div className="align-self-center">{props.text}</div>
                </div>
            </StyledButton>       
    )
}

export default BtnView

const StyledButton = styled(Button)`
background-color: #084c61;
border: none;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    
    :hover {
        background-color: #006266;
    }
    :focus {
        background-color: #006266;
    }

    :disabled {
        background-color: #708090;
        cursor: not-allowed;
    }

    .icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
    margin-right: .2rem;
}

`
