import React, { useEffect, useState } from 'react'
import { addRemark, showRemarks, deleteRemark } from '../../../services/admin'
import Loading from '../../../components/loading'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Modal, Form} from 'react-bootstrap'
import { IoMdAdd } from 'react-icons/io'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { BiTrash } from 'react-icons/bi'
import Swal from 'sweetalert2'
import styled from 'styled-components'

const Remarks = () => {
    const [show, setShow] = useState(false)
    const [remarks, setRemarks] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [updateList, setUpdateList] = useState()
    const { _id } = useParams()

  useEffect(() => {
    let get = async () => {
      setIsLoading(true)
      const rmk = await showRemarks(_id)
      setRemarks(rmk.data)
      setUpdateList(false)
      setIsLoading(false)
    }
    get()
    return () => get = () => {
      
    }
  }, [_id, updateList])

    const handleClose = () => {
        setForm({})
        setShow(false)
    }
    const handleShow = () => setShow(true)

    const [form, setForm] = useState({})
    const handleChange = (attr) => {
        const { name, value } = attr.target
        setForm({
          ...form,
          product: _id,
          [name]: value
        })
      }

      const isFormValid = () => form.remark

      const submitRemark = async (e) => {
        e.preventDefault()
        const message = (type, message) =>
          Swal.fire({
            toast: true,
            position: 'bottom',
            width: '20rem',
            icon: type || "success",
            title: message,
            showConfirmButton: false,
            timer: 2500,
            //background: bg,
            //iconColor: '#fff'       
    
          })
        setIsLoading(true)
        try {
          await addRemark(form)
          message('success', `Observación añadida con éxito`, '#a5dc86')          
          setForm({})
          setUpdateList(true)
          setIsLoading(false)
    
        } catch (error) {
          message('error', `Ha ocurrido un error`, '#f27474')
          setIsLoading(false)
        }
    
      }

      const removeRemark = async (stp) => {
        const message = (type, message) => Swal.fire({
          toast: true,
          position: 'bottom',
          width: '20rem',
          icon: type || '',
          title: message || '',
          showConfirmButton: false,
          timer: 2500
        })
    
        Swal.fire({
          title: `¿Deseas eliminar la observación sobre el producto? `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            deleteRemark(stp._id)
              .then(() => {
                setUpdateList(true)
                message('success', `Observación eliminada con éxito.`)
              })
              .catch(() => message('error', `Ha ocurrido un error`))
          }
        })
      }
    
    return(
        <>
        <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        >
        <StyledModal>
        <Modal.Header>
          <Modal.Title>Nueva observación</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>            
                <Row>
                <Col xs={12} md={12} xl={12}>
                  <Form.Label>Observación:</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    rows={6}
                    className="mb-4"
                    placeholder="Descripción completa"
                    name="remark"
                    value={form.remark || ""}
                    onChange={handleChange}
                  />
                  </Col>
            </Row>
            
        </Modal.Body>
       
        <Modal.Footer>
            <Row className="mt-3">
                <Col>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          </Col>
          <Col>
          <Button variant="success" onClick={submitRemark} disabled={!isFormValid()}>
            Añadir
          </Button>
          </Col>
          </Row>
        </Modal.Footer>
        </StyledModal>
      </Modal>
        <Content>
            <Row className="justify-content-end mb-4">
                <Col xs={12} md={4} xl={3}>
                <Button onClick={handleShow}  className="btn-add"><IoMdAdd className="icon-btn" /> Añadir observaciones</Button>     
                </Col>           
            </Row>  
            { isLoading
            ? <Loading/>
            :  remarks.length !== 0
            ?  remarks.map((rmk, i) => (
              <RowStep className="mb-4" key={i}>
                <div className="step-title">
                <div className="step">
                    <FaRegCalendarAlt className="icon-step"/>
                    {new Intl.DateTimeFormat("es-ES", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit"
                        }).format(new Date(rmk.date))}
                </div>                
                <BiTrash className="icon-trash" onClick={() => removeRemark(rmk)}/>                
                </div>      
                <div className="description">
                <p>{rmk.remark}</p>
                </div>                
            </RowStep>   
            ))
          : <p className="p-no-steps">Todavía no se han hechos observaciones sobre este producto. Haz click en añadir obervaciones para empezar! ;)</p>}
        </Content>
        </>
    )
}

export default Remarks

const Content = styled.div`
.btn-add{
  width: 100%;
}

.p-no-steps {
    color:#888888;    
    margin-bottom: 0px;
    text-align: center;
}
`

const RowStep = styled.div`

.step-title{
    padding: .3rem;
    display:flex;    
    flex-direction: row;
    justify-content: space-between;
    //background-color: #dee2e6;       
    border-top: 1px solid #dee2e6;
    
}

.step {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.action {
    flex: none;
}

.icon-step {
    color: #084c61;
    font-size: 1.3rem;
    margin-right: 7px;
    margin-bottom: .3rem;
    
}

.icon-trash {
    color: #084c61;
    font-size: 1.2rem;
    margin-left: 7px;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
}

.description{
    padding: .3rem;
    p {
    color:#888888;    
    margin-bottom: 0px;
    :first-letter {
      text-transform: uppercase;
    }
}
}

`

const StyledModal = styled.div`
.modal-header{
  background-color: #f8f8fa ;
  display: block;
  padding: 0rem;
  .modal-title {            
            color: #084c61;
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 0;   
            padding: 1rem 2rem 1rem 2rem;        
        }
        .search-wrapper{
          padding: 0 2rem 1rem 2rem;
        }
}
.modal-footer{
  display: block;
  background-color: #f8f8fa;
  padding-top: 0;

  .btn{
    width: 100%;
  }
}


.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}
`