import React, { useEffect, useState} from 'react'
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { FaTags } from 'react-icons/fa'
import { MdRestaurantMenu } from 'react-icons/md'
import { GiFruitBowl } from 'react-icons/gi'
import history from '../../../config/history'
import { listCardsInfo } from '../../../services/admin'

const ProductsCards = () => {
    const [dataCards, setDataCards] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
        let get = async () => {
            setIsLoading(true)
            try {
                const info = await listCardsInfo()
                setDataCards(info.data)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
            }
        }
        get()
        return () => {
            
        }
    }, [])

    return(
        <Content>

        <Row>
                    <Col xs={12} md={4} xl={4}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="title"><FaTags className="icon"/>Categorías</Col>
                            </Row>
                            
                            <Row className="mt-3 mb-2 h48">                            
                                {isLoading ? <Col className="text-center pt-2"><Spinner animation="border" size="xl"/> </Col> :
                                <Col className="counter text-center">{dataCards.categories}</Col> }
                            </Row>
                            {/*<Row >
                                <Col className="info text-center">Activas: 8</Col>
                                <Col className="info text-center">Inactivas: 2</Col>         
                            </Row>*/}
                            <Row className="mt-3">
                                    <Col>
                                        <Button className="btn" onClick={() => history.push('/admin/nueva-categoria')}>Nueva Categoría</Button>
                                    </Col>
                                </Row>

                        </Card.Body>
                    </Card>
                    </Col>
                    <Col xs={12} md={4} xl={4}>
                    <Card>
                        <Card.Body>
                        <Row>
                                <Col className="title"><MdRestaurantMenu className="icon"/>Productos</Col>
                            </Row>                            
                            <Row className="mt-3 mb-2 h48">                            
                                {isLoading ? <Col className="text-center pt-2"><Spinner animation="border" size="xl"/> </Col> :
                                <Col className="counter text-center">{dataCards.products}</Col> }
                            </Row>
                            {/*<Row >
                                <Col className="info text-center">Activos: 18</Col>
                                <Col className="info text-center">Inactivos: 2</Col>         
                            </Row>*/}
                            <Row className="mt-3">
                                    <Col>
                                        <Button className="btn" onClick={() => history.push('/admin/nuevo-producto')}>Nuevo producto</Button>
                                    </Col>
                                </Row>

                        </Card.Body>
                    </Card>
                    </Col>
                    <Col xs={12} md={4} xl={4}>
                    <Card>
                        <Card.Body>
                            
                        <Row>                            
                                <Col className="title"><GiFruitBowl className="icon"/>Ingredientes</Col>
                            </Row>   
                            <Row className="mt-3 mb-2 h48">                            
                                {isLoading ? <Col className="text-center pt-2"><Spinner animation="border" size="xl"/> </Col> :
                                <Col className="counter text-center">{dataCards.ingredients}</Col> }
                            </Row>                            
                            <Row className="mt-3">
                                <Col>
                                        <Button className="btn" onClick={() => history.push('/admin/nuevo-ingrediente')}>Nuevo ingrediente</Button>
                                        </Col>
                            </Row>
                                
                        </Card.Body>
                    </Card>
                        
                    </Col>
                </Row>
                </Content>

    )
}

export default ProductsCards

const Content = styled.div`
.btn {
    width: 100%;
}

.card{
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    color: #084c61;    
    margin-bottom: 1rem;
    min-height: 170px;
    .icon {
        margin-bottom: .2rem;
        margin-right: .3rem;
    }

    .card-info {
        height: 100%;
    }

    .h48 {
        height: 48px;
    }

    .title {
        font-size: 1.1rem;
        font-weight: 600;

    }

    .counter{
        font-size: 2rem;
        font-weight: 600;
    }

    .info {
        font-size: 1rem;
        color: #888888;

    }
    

}

`
