import { createGlobalStyle } from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css'

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        -webkit-font-smoothing: antialiased;
        font-family:'Jost', sans-serif;
    }

    html, body {
    height: 100%;
  }
  body {
    display: flex;
    flex-direction: column;
    background: #F8F8FA;
  }
    
  p {
    font-size: 1.1rem;
  }
    `

    export default GlobalStyle 
    