import React from 'react'
import styled from 'styled-components'
import { IoIosArrowBack } from 'react-icons/io'


const TitlePage = (props) => {
    return (
        <>                      
                
                    <Title>           
                        <div className="container-title">    
                        <IoIosArrowBack className="icon" onClick={props.onClick}/> 
                        <div className="box-title">
                        <h1>{ props.title }</h1>  
                        </div>
                        </div> 
                    </Title>              
                
          
        </>
    )
}



export default TitlePage



const Title = styled.div`
          
    color: #084c61 !important; 
    margin-bottom: 1rem;
    .container-title{
        display: flex;
    }
    .icon{
        font-size: 2rem;
        cursor: pointer;
        margin-top: .2rem;
        margin-right: .5rem;
    }
    
    .box-title{
        width: 100%;
    }

    h1 {
        font-size: 2em;
        ::first-letter{
            text-transform: uppercase;
             
        }
        //text-transform: uppercase;
        font-weight: 600;
        text-align: left;
    }
    
    
`