import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import { listCategory, deleteCategory } from '../../../services/admin'
import { IoMdAddCircleOutline } from 'react-icons/io'
import Loading from '../../../components/loading'
import { BiTrash, BiEdit } from 'react-icons/bi'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import history from '../../../config/history'

const CategoriesList = (props) => {
    const [categories, setCategories] = useState([])
    const [update, setUpdate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        setUpdate(false)
        let get = async () => {
            setIsLoading(true)
            const ctg = await listCategory()
            setCategories(ctg.data)
            setIsLoading(false)
        }
        get()

        return () => get = () => {

        }
    }, [update])

    const removeCategory = async (catg) => {
        const message = (type, message) => Swal.fire({
            toast: true,
            position: 'bottom',
            width: '20rem',
            icon: type || '',
            title: message || '',
            showConfirmButton: false,
            timer: 2500
        })

        Swal.fire({
            title: `¿Deseas eliminar la categoría ${catg.name} ?`,
            showCancelButton: true,
            confirmButtonText: `Eliminar`,
            cancelButtonText: `Cancelar`,
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory(catg._id)
                    .then(() => {
                        setUpdate(true)
                        message('success', `Categoría ${catg.name} eliminada con éxito.`)
                    })
                    .catch(() => message('danger', `Ha ocurrido un error`))
            }
        })
    }



    return (
        <>

            {isLoading
                ? <Loading />
                :
                <RowCategory>

                    <CardNew className="h-100">
                        <Link to={'/admin/nueva-categoria'}>
                            <Card.Body className="d-flex">                              
                                
                                    <div className="new-category">
                                        <IoMdAddCircleOutline className="icon-new" />
                                        <div className="text-new">Nueva categoría</div>
                                    </div>
                                
                                   
                            </Card.Body>
                        </Link>
                    </CardNew>



                    {categories.map((catg, i) => (
                        <CardCategory key={i} >
                            <Card.Header>
                                <div className="product-info">
                                    <div className="product-name">{catg.name}</div>
                                    <div className="actions"><BiTrash className="icon" onClick={() => removeCategory(catg)} /><BiEdit className="icon" onClick={() => history.push(`/admin/categorias/${catg._id}/editar`)} /></div>
                                </div>
                            </Card.Header>
                            <Card.Body >

                                <Card.Img variant="bottom" src={catg.photo} />

                            </Card.Body>
                        </CardCategory>

                    ))}



                </RowCategory>


            }


        </>
    )
}



export default CategoriesList


const CardNew = styled(Card)`
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    a {        
        align-items: center;
    height: 100%;
    text-decoration: none;
    :hover {        
        .new-category{
            color: #db3934;
            transition: ease-in-out .2s;
        }     
    }
}

.card-body {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    
}
    .new-category {
    text-align: center;
    color: #084c61;
    transition: ease-in-out .2s;
    .icon-new {
        
        font-size: 2rem;
        margin-bottom: .5rem;

    }
    .text-new {
        
        font-size: 1.2rem;
        font-weight: 600;
    }
}
    `

const CardCategory = styled(Card)`
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.card-header {
    background-color: #FFFFFF;
}

.product-info {
  color:#888888;
  height: 100%;  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.product-name {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    overflow: hidden;    
    text-overflow: ellipsis;
    white-space: nowrap;      
}

.actions {
    flex: none;
    text-align: right;
}
.icon {
    color: #084c61;
    font-size: 1.2rem;
    margin-left: 7px;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
}

.card-body{
    
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}



.card-title{
    margin: 0px;
    text-transform: uppercase;
}

.card-footer {
    padding: 0.5rem;
    text-align: right;
}

.right{
    text-align: right;
}
`
const RowCategory = styled.div`
margin-top: 1rem;
display: grid;
grid-gap: 1rem;
grid-template-columns: repeat(auto-fill,minmax(240px,1fr));

`
