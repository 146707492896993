import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { authentication } from '../../services/auth'
import { saveToken } from '../../config/auth'
import  history  from '../../config/history'
import http from '../../config/http'

import logo from '../../assets/img/logo.svg'
import { Container, Card, InputGroup, FormControl, Button, Dropdown, Col, Row, Spinner } from 'react-bootstrap'
import { FaLock, FaUser } from 'react-icons/fa'
import { HiHome } from 'react-icons/hi'
import Swal from 'sweetalert2'





const Login = () => {
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)


    const handleChange = (attr) => {
        setForm({
            ...form,
            [attr.target.name] : attr.target.value            
        })
    }

    const isFormValid = () => form.email && form.password

    const submitLogin = async (e) => {
        const message = (message) => Swal.fire({
            toast: true,
            position: 'top',
            width: '20rem',
            icon: 'error',
            title: message,
            showConfirmButton: false,
            timer: 2000           
            
          })
      
        if (isFormValid()) {            
            try {
                setLoading(true)
                const { data } = await authentication(form)
                const { token } = data
                http.defaults.headers["x-auth-token"] = token
                saveToken(data)
                history.push('/admin')
                
            } catch (error) {
                setLoading(false)
                const errorLogin = error.response.data.errors
        if (errorLogin) {
          const items = errorLogin.map(item => item.msg)          
          const msgError = items.toString()
          
                     
          message(msgError)       
        }       

                
            }
        }
        
    }

    const pressEnter = (event) => event.key === 'Enter' ? submitLogin() : null


    return (
        <>
            <Content>
                <Container className="container-form">
                    <RowJustify>
                        <Container>
                            <Row>
                            
                                <Col md={5} lg={5}>
                                    <Card className="cardLogo">
                                        
                                        <Logo>
                                        <img src={logo} alt="Bombocado" className="img"/>
                                        <div className="text">BOMBOCADO</div>
                                        </Logo>
                                    </Card>
                                </Col>
                                <Col md={6} lg={5}>
                                    <Card className="cardForm">
                                        <Card.Body>
                                            <Card.Title className="mb-5">LOGIN</Card.Title>
                                            <InputGroup className="mb-5">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text className="prependBg"><FaUser /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl type="email" name="email" className="input" placeholder="E-mail" onChange={handleChange} value={form.email || ""} onKeyPress={pressEnter}/>
                                            </InputGroup>
                                            <InputGroup className="mb-5">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text className="prependBg"><FaLock /></InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl type="password" name="password" className="input" placeholder="Password" onChange={handleChange} value={form.password || ""} onKeyPress={pressEnter}/>
                                            </InputGroup>
                                            <div className="d-grid gap-2">
                                            <Button variant="dark" block className="mb-5" onClick={submitLogin} disabled={!isFormValid()}>
                                                {loading ? (
                                                    <Spinner animation="border" size="sm" />
                                                ) : "ENTRAR"}
                                            </Button>
                                            </div>
                                            <Dropdown.Divider />
                                            <Card.Text><Link to={'/'}><HiHome className="icon"/>Ir a la página inicial</Link></Card.Text>
                                        </Card.Body>
                                        

     
                                    </Card>
                                    
                                </Col>
                                
                            </Row>
                        </Container>
                    </RowJustify>
                    
                </Container>
            </Content>
        </>
    )
}



export default Login

const Content = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-color: #db3934;

    .container-form {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    a{
        text-decoration:none;
        color: #495057;
    }


    .h5{
        font-size: 1.60rem;
        color: #084c61;
    }

    .cardLogo{
        height: 100%;        
        padding-bottom: 20px;
        background-color: #db3934;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        
        
    }
    
    .cardForm{
        background-color: #FFF;
        border: none;
        border-radius: 0px;
        
    }

   
    .prependBg{
        background-color: #FFF;
        border-top: none;
        border: none;
        border-radius: 0px;
        color: #084c61;
    }

    

.input{
    background-color: #FFF;    
    border: none;
    border-radius: 0px;    
          
}

.input-group{
        border-bottom: 2px solid #084c61;
    }


.input-group-prepend{
        padding-top: 5px;
    }

.btn {
    background-color: #084c61;
    border: none;
    border-radius: 0px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :hover {
        background-color: #006266;
    }
    :disabled {
        background-color: #708090;
        cursor: not-allowed;
    }

}


.icon{
    font-size: 1.2em;
    margin-right: 10px;
}
   
`

const Logo = styled.div`
text-align: center;
.img{
    width: 5rem;    
}

.text{
    color: #FFF;
    font-size: 2rem;
}
`


const RowJustify = styled.div`
    display:flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    text-align: center;

    
    
`
