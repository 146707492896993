import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'


const TitlePage = ({ title }) => {
    return (
        <>
            <Container>                
                <ContainerTitle>
                    <Title>                
                        <h1>{ title }</h1>  
                    </Title>              
                </ContainerTitle>
            </Container>
        </>
    )
}



export default TitlePage


const ContainerTitle =styled.div`
    padding: 2rem 0px 0rem 0px;
    
`
const Title = styled.div`
    border-bottom: 2px solid #084c61;        
    color: #084c61 !important; 
    
    h1 {
        font-size: 2em;
        ::first-letter{
            text-transform: uppercase;
        }
        //text-transform: uppercase;
        font-weight: 600;
        text-align: center;
    }
    
    
`