import React from 'react'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import history from './config/history'

import Portal from './views/portal'
import Login from './views/auth'
import Admin from './views/admin'

import { isAuthenticated } from '../src/config/auth'

const AdminRoute = ({ ...rest }) => {

    if (!isAuthenticated()) {
         return <Redirect to='/login' />
     }
     return <Route  {...rest} />
 } 


const Routers = () => (
    <Router history ={history}>
         
        <Switch>            
            <Route path="/login"component={Login} /> 
            <AdminRoute path="/admin"component={Admin} />
            <Route path="/" component={Portal} />             
        </Switch>
        
    </Router>


)

export default Routers