import React, { useState, useEffect, useRef } from 'react'
import { addCategory, updateCategory, showCategory } from '../../../services/admin'
import Swal from 'sweetalert2'
import { Row, Col, Form, Button, Container, ButtonGroup, ToggleButton, Image } from 'react-bootstrap'
import TitleSubPage from '../../../components/layout/admin/titleSubPage'
import BtnSave from '../../../components/layout/admin/buttons/btnSave'
import BtnCancel from '../../../components/layout/admin/buttons/btnCancel'
import { useParams, useLocation } from 'react-router-dom'
import history from '../../../config/history'
import Loading from '../../../components/loading'
import { FaCheck, FaTimes } from "react-icons/fa"
import { RiImageAddLine } from "react-icons/ri"
import styled from 'styled-components'

const FormProducts = (props) => {

  const [form, setForm] = useState({})
  const { _id } = useParams()


  const [updatePhoto, setUpdatePhoto] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fileInput = useRef(null)
  const isUpdate = useLocation().pathname !== '/admin/nueva-categoria'

  useEffect(() => {
    setIsLoading(true)
    let getCategory = async () => {
      if (isUpdate) {
        const category = await showCategory(_id)
        setForm(category.data)
        setIsLoading(false)

      } else {
        //setForm({})
        setUpdatePhoto(true)
        setIsLoading(false)
      }
    }
    getCategory()

    return () => getCategory = () => {
      //cleanup
    }
  }, [_id, isUpdate])



  const handleChange = (attr) => {
    const { name, value, checked } = attr.target
    const isCheck = name === "status"
    if (name === "photo") {
      setForm({
        ...form,
        [name]: value,
        photo: attr.target.files[0],
        photoName: attr.target.files[0].name
      })
    } else {
      setForm({
        ...form,
        [name]: isCheck ? checked : value

      })
    }
    //return
  }


  const typeReq = (data) => isUpdate ? updateCategory(form._id, data) : addCategory(data)

  const isFormValid = () =>
    form.name && form.photo && form.url

  const clearForm = () => {
    setForm({
      name: "",
      status: false,
      //photo: "",
      photoName: "",
      url: ""
    });
  };

  //const clearFile = () => {
  //  let file = document.getElementById('photo-upload')
  //  file.value = ""
  //}

  const submitCategory = async () => {
    const message = (type, message) =>
      Swal.fire({
        toast: true,
        position: 'bottom',
        width: '20rem',
        icon: type || "success",
        title: message,
        showConfirmButton: false,
        timer: 2500,
      })

    setIsLoading(true)


    let data = new FormData()
    Object.keys(form)
      .forEach(key => data.append(key, form[key]))
    if (typeof form.photo === "string") {
      data.delete('photo')
    }


    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    }
    setIsLoading(true)

    try {
      await typeReq(data, config)
      message('success', `Categoría ${isUpdate ? "actualizada" : "creada"} con éxito`)
      history.push('/admin/categorias')
      clearForm()
      //clearFile()
      setIsLoading(false)
    } catch (error) {
      message('error', `Ha ocurrido un error`)
      setIsLoading(false)

    }
  }

  const removePhoto = () => {
    setUpdatePhoto(true)
    setForm({
      ...form,
      photo: "",
    })
  }


  return (
    <>
      <TitleSubPage title={isUpdate ? "Editar categoría" : "Nueva categoría"} onClick={() => history.goBack()} />

      {isLoading
        ? <Loading />
        :
        <Content>
          <Container className="p-4">
            <Form>
              <Row>
                <Col xs={12} md={4} xl={4}>
                  <Col xs={12} md={12} xl={12}>
                    <Form.Label>Foto</Form.Label>
                    <Form.Group className="d-grid gap-2">
                      {!updatePhoto ? (
                        <>
                          <Image src={form.photo} thumbnail />
                          <ActionPhoto onClick={removePhoto}><FaTimes className="icon" />Eliminar Foto</ActionPhoto>
                        </>
                      ) : (
                        <>
                          <input ref={fileInput} name="photo" id="photo-upload" type="file" onChange={handleChange} className="custom-file" />
                          <Button variant="outline-secondary" className="mb-4" onClick={() => fileInput.current.click()} >
                            <RiImageAddLine className="icon-btn" /> Seleccionar
                          </Button>
                        </>
                      )}
                    </Form.Group>
                    <Row>
                      <Col className="justify-content-end">
                        <p>{form.photoName}</p>
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col xs={12} md={8} xl={8}>
                <div className="d-flex flex-column h-100">
                  <Row>
                    <Col xs={12} md={4} xl={4}>                    
                      <Form.Label>Status</Form.Label>
                      <Form.Group className="d-grid gap-2">
                      <ButtonGroup toggle className="mb-4">
                      <ToggleButton
                      type ="checkbox"
                      variant={Boolean(form.status)?"outline-success": "outline-danger"}
                      name="status"
                      onChange={handleChange}
                      checked={Boolean(form.status)}
                      className="custom-checkbox"
                      >
                    {Boolean(form.status)?<><FaCheck className="icon-btn" /> Activo </>: <><FaTimes className="icon-btn" /> Inactivo</>}
                      </ToggleButton>
                      </ButtonGroup>

                      </Form.Group>
                    </Col>
                    <Col xs={12} md={8} xl={8} >
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        className="mb-4"
                        type="text"
                        placeholder="Nombre de la categoría"
                        name="name"
                        value={form.name || ""}
                        onChange={handleChange}
                      />
                      <Form.Label>URL</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        className="mb-4"
                        type="text"
                        placeholder="friendly-url"
                        name="url"
                        value={form.url || ""}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-end  mt-auto">
                    <Col xs={12} md={4} xl={4} className="mb-3">
                      <BtnCancel onClick={() => history.goBack()} />
                    </Col>
                    <Col xs={12} md={4} xl={4} className="mb-3">
                      <BtnSave onClick={submitCategory} disabled={!isFormValid()} />
                      
                    </Col>
                  </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </Content>
      }

    </>
  )
}

export default FormProducts

const Content = styled.div`
background-color: #ffffff;
border: 1px solid #dee2e6;
border-radius: .25rem;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.custom-select {
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #6c757d;
    padding: .375rem .75rem;
    width: 100%;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {   
  border-color: #86b7fe;
  box-shadow:  0 0 10px rgba(134, 183, 254);
  outline: 0 none;
}
}

.custom-checkbox{
    input {
        display: none;
    }
}

.custom-file{
    display: none;
  //color: transparent;
}

.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}

.icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
}

.actions{
    display: flex;
    justify-content: space-between;
}
`

const ActionPhoto = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #212529;
    &:hover {
      color: #dc3545;
    }
    .icon {
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
  
`

