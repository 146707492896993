import React, { useState, useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { listProductsByCategory } from '../../../services/portal'
import Loading from '../../../components/loading'
import { Link, useParams, useLocation } from 'react-router-dom'
import TitleSubPage from '../../../components/layout/portal/titleSubPage'
import history from '../../../config/history'



const ProductsList = (props) => {
    const [products, setProducts] = useState([])   
    const [isLoading, setIsLoading] = useState(false)
    const [emptyData, setEmptyData] = useState()
    const { category_name } = useParams()
    const category = useLocation().pathname
    const category_title = category_name.replaceAll('-', ' ')    


    useEffect(() => {        
        window.scrollTo(0,0)        
            let get = async () => { 
                setIsLoading(true)
                const prd = await listProductsByCategory(category_name) 
                if (prd.data.length === 0) {
                    setEmptyData(true)
                    setIsLoading(false)
                } else {
                    setProducts(prd.data)
                    setIsLoading(false)
                }
                
            }        
                get()       
            
            return () => {
                //cleanup
            }
            
        //}
        
    }, [category_name])

    
    
         


    return (
        <>    
        <Content>      
        <TitleSubPage title={category_title} onClick={() => history.goBack()} />
        {isLoading
        ? <Loading/>
        : 
            <>
                {emptyData 
                ? <p> Categoría sin productos :( </p> 
                :        
                <div className="products">
            {products.map((prd, i) => (
                <CardProduct  key={i}>
                    <Link to={`${category}/${prd.url}` }>
                <Img>
                <Card.Img src={prd.photo} />
                </Img>
                <Card.Body>
                    <Card.Title>{prd.title}</Card.Title>             
                </Card.Body>                
                <Price>
                    {prd.price}€
                </Price>
                </Link>
               
              </CardProduct>
                
                ))}
                </div>
                }
            </>
             }
            </Content>
       
        </>
    )
}



export default ProductsList

const Content = styled(Container)`
//margin-top: 2rem;
margin-bottom: 2rem;

p {
    color: #084c61;  
    font-size: 1.1rem;
    text-align: left;          
            
}

.products {
    display: grid;
    grid-gap: .8rem;
    grid-template-columns: 1fr 1fr;

}

@media (min-width: 600px) and (orientation: landscape) {
    .products {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;

}
}

@media screen and (min-width: 768px) {
    .products {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;

}
}

@media screen and (min-width: 1025px) {
    .products {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
}

`


const CardProduct = styled(Card)`
//width: 25rem;
//margin-bottom: 1.5rem;
border-radius: 0px;
//border: thin solid #dedede;
border: none;
background-color: transparent;



a {
    text-decoration: none;
}

.card-img{
    border-radius: 0px;
    transition: ease-in-out .15s;
    //background-color: #ddedf9;
    
}

.card-title{
   // white-space: nowrap;.
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    //text-transform: capitalize;
    text-align: center; 
    color: #DB3934;
    font-weight: bold;
    font-size: 1.2rem;
    //border-bottom: thin solid #dedede;
    //padding-bottom: 1rem;

    :first-letter{
    text-transform: uppercase;
    
}
    
}

.card-body{
    padding: 1rem 1rem 0 1rem;
    background-color: transparent;

}

.card-text{
   // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //margin: 0px;    
    font-size: 1.1rem;
    color: #9c9c9c;
        
}

:hover {
    //box-shadow: 0 0 15px 0 rgb(124 120 111 / 16%);
    .card-img{
        transform: scale(1.05);    }
    border: none;      
    
}


`
const Price = styled.div`
    padding: 0rem 1rem 1rem;
    color: #084c61;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: right;
`


const Img = styled.div`
//max-height: 200px;
overflow: hidden;
`