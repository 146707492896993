import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getUser, removeToken } from '../../../config/auth'
import history from '../../../config/history'

import { Container } from 'react-bootstrap'
import logo from '../../../assets/img/logo.svg'
import { FaTags, FaRegUserCircle, FaSignOutAlt } from 'react-icons/fa'
import { MdRestaurantMenu, MdClose, MdMenu } from 'react-icons/md'
import { BiTachometer } from 'react-icons/bi'
import { GiFruitBowl } from 'react-icons/gi'



const Menu = [
    {
        name: "DASHBOARD",
        icon: <BiTachometer/>,
        link: "/",
        cName: 'nav-text'
    },
    
    {
        name: "CATEGORÍAS",
        icon: <FaTags/>,
        link: "/categorias",
        cName: 'nav-text'
    },
    {
        name: "PRODUCTOS",
        icon: <MdRestaurantMenu/>,
        link: "/productos",
        cName: 'nav-text'
    },
    {
        name: "INGREDIENTES",
        icon: <GiFruitBowl/>,
        link: "/ingredientes",
        cName: 'nav-text'
    }
  
]

const AdminLayout = ({children}) => {

  const width = window.innerWidth

  console.log(width)

    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)

    const logout = () => {
      removeToken()
      history.push('/')
  }
    return (
        <>
        <Header>        
          <div className='navbar'>
          <Container fluid className="justify-content-start">                    
            <div className='menu-bars'>
              {sidebar ? <MdClose onClick={showSidebar} /> : <MdMenu onClick={showSidebar}/> } 
            </div>
            <div className="logo">
              <img src={logo} alt="Bombocado" className="logo-img"/>
              <div className="logo-text">BOMBOCADO</div>
            </div>            
            
          </Container>
        </div>        
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>        
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
            <div className='menu-bars'>
                
              </div>
            </li>
            {Menu.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={'/admin' + item.link}>
                    {item.icon}
                    <span>{item.name}</span>
                  </Link>
                </li>                
              );
            })}
            <li className="nav-text">            
              <FaRegUserCircle/> <span>{getUser().name}</span>
                                                
          
            </li>
            <li onClick={logout} className="nav-text">
            <FaSignOutAlt /> <span>Cerrar sesión</span>
            </li>
            
          </ul>
          
        </nav>                     
      </Header>
      <Content>
          {children}
        </Content>
        </>
    );
}

export default AdminLayout


const Header = styled.div`
.navbar {
  background-color: #db3934;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  height: 60px;
  display: flex;
  //justify-content: start;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 20;

}

.menu-bars {
  color: #ffffff;
  //margin-left: 2rem;
  font-size: 1.7rem;
  background: none;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  
  .logo-text{
    margin-left: 0.3em;
    color: #ffffff;
    font-size: 1.6rem;
  }
}

.nav-user {
  color: #ffffff;
  .icon{
    margin-right: 5px;
  }
  
}

.nav-menu {
  background-color: #db3934;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 20;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 19;
  
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  color: #084c61;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #db3934;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span {
  margin-left: 16px;
}
    
`
const Content = styled(Container)`
  margin-top: 60px;
  padding-top: 2rem;
  padding-bottom: 2rem;
`
