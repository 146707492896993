import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { BiCake } from 'react-icons/bi'
import TitleSubPage from '../../../components/layout/portal/titleSubPage'
import BtnView from '../../../components/layout/portal/buttons/btnView'
import history from '../../../config/history'
import styled from 'styled-components'

const Tartas = () => {
   
    window.scrollTo(0,0)

    return(
        <Content>        
            <TitleSubPage title="Tartas" onClick={() => history.goBack()} />
            <Row>
                <Col xs={12} md={4} xl={4}>
                    <Card className="text-center">
                        <Card.Body>
                        <Card.Title>Naked cake</Card.Title>                        
                        <Card.Text>12 - 15 raciones</Card.Text>
                        <Card.Img src="https://bdkn-bucket.s3-eu-west-1.amazonaws.com/img/tartas/chocolate-coco-naked.jpg"/>                        
                        <BtnView onClick={() => history.push('/tartas/naked-cake')} icon={<BiCake/>} text="Ver Tartas"/>
                        </Card.Body>
                    </Card>
                    
                </Col>
                <Col xs={12} md={4} xl={4}>
                <Card className="text-center">
                        <Card.Body>
                        <Card.Title>Piscina mediana</Card.Title>                        
                        <Card.Text>10 - 12 raciones</Card.Text>
                        <Card.Img src="https://bdkn-bucket.s3-eu-west-1.amazonaws.com/img/tartas/chocolate-oreo-piscina.jpg"/>
                        <BtnView onClick={() => history.push('/tartas/piscina-mediana')} icon={<BiCake/>} text="Ver Tartas"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} xl={4}>
                <Card className="text-center">
                        <Card.Body>
                        <Card.Title>Piscina pequeña</Card.Title>                        
                        <Card.Text>6 - 8 raciones</Card.Text>
                        <Card.Img src="https://bdkn-bucket.s3-eu-west-1.amazonaws.com/img/tartas/red-velvet-piscina.jpg"/>
                        <BtnView onClick={() => history.push('/tartas/piscina')} icon={<BiCake/>} text="Ver Tartas"/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="row-exp">
                <Col>
                    <h2>Y si me sobra tarta... ¿Qué hago?</h2>
                    <p>No te preocupes, puedes guardarla en la nevera hasta 5 días para ir disfrutándola poco a poco. Otra opción es partir lo que sobre en raciones individuales, envolverlas en papel aluminio y congelarlas.</p>
                </Col>
            </Row>
        </Content>

    )
}

export default Tartas

const Content = styled(Container)`

.card {
    border: 1px solid #dee2e6;
    border-radius: 8px;    
    margin-bottom: 1rem;
   
 
}

.card-body {
    padding: 0px;    
}

.card-title {       
    text-align: center;
    color: #084c61;
    font-size: 1.3rem;
    font-weight: 600;   
    margin-bottom: 0px;   
    margin-top: 1rem;  
}
.card-text {
            color: #084c61;
            font-size: 1.1rem;
            text-align: center;          
            color: #084c61;  
            margin-bottom: 1rem;
        }

.btn{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #084c61;
    text-align: center;
}

p {
    font-size: 1.1rem;
    color: #084c61;
    text-align: center;
    max-width: 750px;
    margin: auto;
}

.row-exp {
    margin-top: 1rem;
    margin-bottom: 2rem;
}
`