import React, { useState, useEffect } from 'react'
import { Col, Container, Row, InputGroup, FormControl} from 'react-bootstrap'
import TitleSubPage from '../../../components/layout/portal/titleSubPage'
import BtnView from '../../../components/layout/portal/buttons/btnView'
import styled from 'styled-components'
import { FaUser, FaWhatsapp } from 'react-icons/fa'
import { MdMailOutline, MdPhone } from 'react-icons/md'
import { BiMailSend } from 'react-icons/bi'
import { sendMail } from '../../../services/portal'
import Swal from 'sweetalert2'
import history from '../../../config/history'


const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [form, setForm] = useState({})
    //const [loading, setLoading] = useState(false)


    const handleChange = (attr) => {
        setForm({
            ...form,
            [attr.target.name]: attr.target.value
        })
    }

    const isFormValid = () => form.name && form.email && form.message

    const submitForm = async () => {
        const message = (type, message) => Swal.fire({
            title: message,
            icon: type,
            showConfirmButton: false,
            timer: 2000
        })

        if (isFormValid()) {
            //setLoading(true)

            try {
                await sendMail(form)
                message('success', 'Mensaje enviado con éxito')
                //setLoading(false)
                setForm({})


            } catch (error) {
                message('error', 'Error al enviar el mensaje')
                //setLoading(false)
            }
        }
    }

    const pressEnter = (event) => event.key === 'Enter' ? submitForm() : null

    return (
        <>

         
            <ContainerContact>
            <TitleSubPage title="Contacto" onClick={() => history.goBack()} />
                <Row className="mb-4">
                    <Col md={6} className="mb-4">
                        <ColumnInfo>
                            <WrapInfo>
                                <TextContactGroup className="mb-4">
                                    <h4>Teléfono y Whatsapp</h4>
                                    <TextContact>
                                        <MdPhone className="card-icon" />91 948 91 82
                                        <a href="https://wa.me/34602449031?text=Hola%20Bombocado!%20:)" target="_blank" rel="noopener noreferrer" >
                                            <FaWhatsapp className="card-icon" />
                                            602 44 90 31
                                        </a>
                                    </TextContact>
                                </TextContactGroup>
                                <TextContactGroup className="mb-4">
                                    <h4>E-mail</h4>
                                    <TextContact>
                                        <MdMailOutline className="card-icon" />hola@bombocado.es

                                    </TextContact>
                                </TextContactGroup>
                                <TextContactGroup >
                                    <h4>Dónde estamos</h4>
                                    <TextContact>Mercado de Vicálvaro</TextContact>
                                    <TextContact>Calle Velilla, 11 - Local 14</TextContact>
                                    <TextContact>28032 - Madrid</TextContact>
                                </TextContactGroup>



                            </WrapInfo>
                        </ColumnInfo>


                    </Col>

                    <Col md={6}>
                    <WrapInfo className="mb-4">
                            <h4>Formulario de contacto</h4>
                            <TextContact>
                                O si prefieres, puedes usar nuestro formulario de contacto. ¡Estaremos encantados de atenderte! :)
                            </TextContact>
                        </WrapInfo>
                        <ColumnForm>    
                            <InputGroup className="mb-4">
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="prependBg"><FaUser /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="text" name="name" className="input" placeholder="Nombre" onChange={handleChange} value={form.name || ""} onKeyPress={pressEnter} />
                            </InputGroup>
                            <InputGroup className="mb-4">
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="prependBg"><MdMailOutline /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl type="email" name="email" className="input" placeholder="E-mail" onChange={handleChange} value={form.email || ""} onKeyPress={pressEnter} />
                            </InputGroup>
                            <InputGroup>
                                <FormControl as="textarea" name="message" className="input mb-4" placeholder="Escribe un mensaje" onChange={handleChange} value={form.message || ""} onKeyPress={pressEnter} rows={4} />

                                </InputGroup>
                                <Col className="d-flex justify-content-end">
                                <BtnView onClick={submitForm} disabled={!isFormValid()} icon={<BiMailSend/>} text="Enviar"/>                              
                                </Col>
                        </ColumnForm>
                    </Col>    
                </Row>
            </ContainerContact>
        </>
    )
}



export default Contact

const ContainerContact = styled(Container)`
margin-top: 2rem;
margin-bottom: 2rem;
`
const ColumnInfo = styled.div`

`

const WrapInfo = styled.div`
a {
        text-decoration: none;
        display: flex;
    }

    h4 {
        font-size: 1.3em;
        font-weight: 600;
        color: #084c61;
        //text-align: center;
    }

    .table{
        color: #084c61;
        font-size: 1.1rem;
        padding: 20px;        
        thead{
            background-color: #ffffff;
            border-bottom: 1px solid #ced4da;
        }
    
    }
    .card-icon{
            font-size: 1.3em;
            margin-right: 0.5em;
            margin-bottom: 0.2em;
        }
`

const TextContactGroup = styled.div`

    
`
const TextContact = styled.div`
font-size: 1.1rem;
color: #084c61;

a {
    font-size: 1.1rem;
    color: #084c61;
}
`

const ColumnForm = styled.div`
 .prependBg{
        background-color: #FFF;
        border-top: none;
        border-right: none;
        //border-bottom: 2px solid;
        border-left: none;
        border-radius: 0px;
        color: #084c61;
        height: 100%;        
    }

    

.input{
    background-color: #FFF;
    border-top: none;
    border-right: none;
    //border-bottom: 2px solid #084c61;
    border-left: none;
    border-radius: 0px;
    font-size: 1.1rem;
    color: #084c61;
          
}




.icon{
    font-size: 1.2em;
    margin-right: 10px;
}
   

`
