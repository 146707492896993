import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { getUser } from '../../../config/auth'
import styled from 'styled-components'


const Hello = () => {
    const sayHello = () => {
        let date = new Date()
        let hour = date.getHours()

        switch (true) {
            case hour >= 5 && hour < 14:
                return 'Buenos días'

            case hour >= 14 && hour < 21:   
                return 'Buenas tardes'

            case hour >= 21 && hour <= 23:
                return 'Buenas noches'

            case hour >= 0 && hour <= 1:
                return 'Buenas noches'

            case hour > 1 && hour < 5:
                return 'Creo que deberías estar durmiendo'

            default:
                return 'No sé en qué mundo vives, pero en el nuestro esa hora no existe'
        }

    }

    return (
        <Content>
            <Row>
                <Col>
                    <h1>¡{sayHello()}, {getUser().name}!</h1>
                </Col>
            </Row>
        </Content>
    )

}

export default Hello

const Content = styled.div`
margin-bottom: 1rem;

h1 {
    color: #084c61 !important;
    font-size: 2em;
    font-weight: 600;
}
`