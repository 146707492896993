import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa"

const BtnSave = (props) => {
    return (      
            <StyledButton variant="secondary" onClick={props.onClick}><FaTimes className="icon-btn"/>Cancelar</StyledButton>       
    )
}

export default BtnSave

const StyledButton = styled(Button)`
width: 100%;
    .icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
    margin-right: .2rem;
}

`
