import http from '../config/http'



// POST
const sendMail = (data) => http.post(`/contact`, data)

// GET
const listProducts = () => http.get(`/menu`)
const listCarousel = () => http.get(`/carousel`)
const listProductsByCategory = (category) => http.get(`/productsbycategory/${category}`)
const ProductSheet = (product) => http.get(`/menu/${product}`)
const listCategories = () => http.get(`/categories`)
const wakeUp = () => http.get(`/`)



export {
    listProducts,
    listCarousel, 
    listProductsByCategory,
    ProductSheet,
    listCategories,
    sendMail,
    wakeUp
}