import React, {useContext} from 'react'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import styled from 'styled-components'


function ContextAwareToggle({ children, eventKey, callback }) {
const currentEventKey = useContext(AccordionContext);

const decoratedOnClick = useAccordionToggle(
eventKey,
() => callback && callback(eventKey),
);

const isCurrentEventKey = currentEventKey === eventKey;

return (
<CardHeader onClick={decoratedOnClick}>
  <h4>{ children }</h4>{isCurrentEventKey ? <IoIosArrowUp className="icon"/> : <IoIosArrowDown className="icon"/>}
</CardHeader>
);
}

export default ContextAwareToggle

const CardHeader = styled.div`
padding: .5rem 1rem;
cursor: pointer;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
.icon {
        color: #084c61;
        font-size: 2rem;
        margin-right: .5rem;
    }
`