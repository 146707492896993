import React, { useEffect } from 'react'
import TitleSubPage from '../../../components/layout/portal/titleSubPage'
import { Container} from 'react-bootstrap'
import styled from 'styled-components'
import history from '../../../config/history'
import Carta from './carta'

const Products = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <>
        <Content>
            
                <TitleSubPage title="Nuestra carta" onClick={() => history.goBack()} />                 
                <Carta/>
                </Content>   
           
        </>
    )
}



export default Products

const Content = styled(Container)`

`