import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { BiSave } from "react-icons/bi"

const BtnSave = (props) => {
    return (      
            <StyledButton variant="primary" onClick={props.onClick} disabled={props.disabled} ><BiSave className="icon-btn"/>Guardar</StyledButton>       
    )
}

export default BtnSave

const StyledButton = styled(Button)`
width: 100%;
    .icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
    margin-right: .2rem;
}

`
