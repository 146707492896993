import React, { useEffect, useState } from 'react'
import { addStep, showSteps, deleteStep } from '../../../services/admin'
import Loading from '../../../components/loading'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Modal, Form} from 'react-bootstrap'
import { IoMdAdd } from 'react-icons/io'
import { HiCheckCircle } from 'react-icons/hi'
import { BiTrash } from 'react-icons/bi'
import Swal from 'sweetalert2'
import styled from 'styled-components'

const Directions = () => {
    const [show, setShow] = useState(false)
    const [steps, setSteps] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [updateList, setUpdateList] = useState()
    const { _id } = useParams()

  useEffect(() => {
    let get = async () => {
      setIsLoading(true)
      const stp = await showSteps(_id)
      setSteps(stp.data)
      setUpdateList(false)
      setIsLoading(false)
    }
    get()
    return () => get = () => {
      
    }
  }, [_id, updateList])

    const handleClose = () => {
        setForm({})
        setShow(false)
    }
    const handleShow = () => setShow(true)

    const [form, setForm] = useState({})
    const handleChange = (attr) => {
        const { name, value } = attr.target
        setForm({
          ...form,
          product: _id,
          [name]: value
        })
      }

      const isFormValid = () => form.step && form.description

      const submitStep = async (e) => {
        e.preventDefault()
        const message = (type, message) =>
          Swal.fire({
            toast: true,
            position: 'bottom',
            width: '20rem',
            icon: type || "success",
            title: message,
            showConfirmButton: false,
            timer: 2500,
            //background: bg,
            //iconColor: '#fff'       
    
          })
        setIsLoading(true)
        try {
          await addStep(form)
          message('success', `Paso añadido con éxito`, '#a5dc86')          
          setForm({})
          setUpdateList(true)
          setIsLoading(false)
    
        } catch (error) {
          message('error', `Ha ocurrido un error`, '#f27474')
          setIsLoading(false)
        }
    
      }

      const removeStep = async (stp) => {
        const message = (type, message) => Swal.fire({
          toast: true,
          position: 'bottom',
          width: '20rem',
          icon: type || '',
          title: message || '',
          showConfirmButton: false,
          timer: 2500
        })
    
        Swal.fire({
          title: `¿Deseas eliminar el paso ${stp.step} de la preparación? `,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            deleteStep(stp._id)
              .then(() => {
                setUpdateList(true)
                message('success', `Paso eliminado con éxito.`)
              })
              .catch(() => message('error', `Ha ocurrido un error`))
          }
        })
      }
    
    return(
        <>
        <Modal 
        show={show} 
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        >
        <StyledModal>
        <Modal.Header>
          <Modal.Title>Nuevo paso</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
            <Row className="mb-3">
            <Col xs={8} md={6} xl={6} className="mt-2"> 
            <Form.Label>Nº paso:</Form.Label>                 
                  <Form.Control
                  autoComplete="off"
                  type="number"
                  id="inlineFormInputName"      
                  placeholder="Ej.: 1"
                  name="step"
                  value={form.step || ""}
                  onChange={handleChange}
                  min="1"
                  max="20"
                />      
                </Col>
                </Row>
                <Row>
                <Col xs={12} md={12} xl={12}>
                  <Form.Label>Descripción:</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    rows={6}
                    className="mb-4"
                    placeholder="Descripción completa"
                    name="description"
                    value={form.description || ""}
                    onChange={handleChange}
                  />
                  </Col>
            </Row>
            
        </Modal.Body>
       
        <Modal.Footer>
            <Row className="mt-3">
                <Col>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          </Col>
          <Col>
          <Button variant="success" onClick={submitStep} disabled={!isFormValid()}>
            Añadir
          </Button>
          </Col>
          </Row>
        </Modal.Footer>
        </StyledModal>
      </Modal>
        <Content>
            <Row className="justify-content-end mb-4">
                <Col xs={12} md={4} xl={3}>
                <Button onClick={handleShow}  className="btn-add"><IoMdAdd className="icon-btn" /> Añadir paso</Button>     
                </Col>           
            </Row>  
            { isLoading
            ? <Loading/>
            :  steps.length !== 0
            ?  steps.map((stp, i) => (
              <RowStep className="mb-4" key={i}>
                <div className="step-title">
                <div className="step">
                    <HiCheckCircle className="icon-step"/>Paso {stp.step}
                </div>                
                <BiTrash className="icon-trash" onClick={() => removeStep(stp)}/>                
                </div>      
                <div className="description">
                <p>{stp.description}</p>
                </div>                
            </RowStep>   
            ))
          : <p className="p-no-steps">Todavía no se han indicado los pasos de cómo preparar esta receta. Haz click en añadir pasos para empezar! ;)</p>}
        </Content>
        </>
    )
}

export default Directions

const Content = styled.div`
.btn-add{
  width: 100%;
}

.p-no-steps {
    color:#888888;    
    margin-bottom: 0px;
    text-align: center;
}
`

const RowStep = styled.div`

.step-title{
    padding: .3rem;
    display:flex;    
    flex-direction: row;
    justify-content: space-between;
    //background-color: #dee2e6;       
    border-top: 1px solid #dee2e6;
    
}

.step {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.action {
    flex: none;
}

.icon-step {
    color: #084c61;
    font-size: 1.3rem;
    margin-right: 7px;
    margin-bottom: .3rem;
    
}

.icon-trash {
    color: #084c61;
    font-size: 1.2rem;
    margin-left: 7px;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
}

.description{
    padding: .3rem;
    p {
    color:#888888;    
    margin-bottom: 0px;
    :first-letter {
      text-transform: uppercase;
    }
}
}

`

const StyledModal = styled.div`
.modal-header{
  background-color: #f8f8fa ;
  display: block;
  padding: 0rem;
  .modal-title {            
            color: #084c61;
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 0;   
            padding: 1rem 2rem 1rem 2rem;        
        }
        .search-wrapper{
          padding: 0 2rem 1rem 2rem;
        }
}
.modal-footer{
  display: block;
  background-color: #f8f8fa;
  padding-top: 0;

  .btn{
    width: 100%;
  }
}


.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}
`