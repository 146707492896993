import React, { useState, useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { listCategories } from '../../../services/portal'
import Loading from '../../../components/loading'

const CategoriesList = (props) => {
    const [categories, setCategories] = useState([])
    const [update, setUpdate] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
       

    useEffect(() => {
        setUpdate(false)
        
        let get = async () => { 
            setIsLoading(true)
            const ctg = await listCategories() 
            setCategories(ctg.data)
            setIsLoading(false)     
        }        
            get()                  
        return () => get = () => {             
        }
    }, [update])   
    

    return (
        <>
        {isLoading
        ? <Loading/>
        :
            <Content>                
                <div className="menu">                
                    {categories.map((catg, i) => (                        
                        <Card key={i} >
                            <Link to={'/carta/' + catg.url}>                            
                        <Card.Body >
                        <Card.Img src={catg.photo} />    
                                            
                        <Card.Title>{catg.name}</Card.Title>                                 
                         
                        </Card.Body>                        
                        </Link>                         
                        </Card>
                    ))}                                      
                    </div>               
            </Content>
                    }
        </>
    )
}



export default CategoriesList

const Content = styled(Container)`
margin-top: 2rem;
margin-bottom: 2rem;

.card {
    border: 1px solid #dee2e6;
    border-radius: 8px;    
    overflow: hidden;

.card-body {
    padding: 0px;    
}


a {
    text-decoration: none;
}

.card-img{
    border-radius: 0px;
    transition: ease-in-out .15s;    
}


.card-title {   
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: capitalize;    
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.3rem;
    transition: ease-in-out .15s;   
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}


:hover {        
    .card-img {
        transform: scale(1.02);        
    }    

    .card-title {
        background-color: #db3934;
        color: #fff;
        //border-bottom: 2px solid #084c61;

    }
    
}

.card-footer {
    background-color: #ffffff;
    }
}

.menu {
    display: grid;
    grid-gap: .8rem;
    grid-template-columns: 1fr 1fr;
}

@media (min-width: 600px) and (orientation: landscape) {
    .menu {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;

}

}

@media screen and (min-width: 768px) {
    .menu {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
}

.card {
    .card-title {
    font-size: 1.5rem;
}
}

}

@media screen and (min-width: 1025px) {
    .menu {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
}
}

`

