import React from 'react'
import { Route } from 'react-router-dom'



import Layout from '../../components/layout/portal'
import About from './about'
import Home from './home'
import ProductsList from './products/list'
import ProductSheet from './products/sheet'
import Cakes from './products/cakes'
import CakesList from './products/cakeslist'
import Categories from './products'
import Services from './services'
import Contact from './contact'



const View = (props) => {
     
  

    return (
         
        <Layout>         
            <Route exact basename={props.match.path} path={props.match.path + '/'} component={Home}/>     
            <Route exact basename={props.match.path} path={props.match.path + 'carta'} component={Categories}/>
            <Route exact basename={props.match.path} path={props.match.path + 'carta/:category_name'} component={ProductsList}/>
            <Route exact basename={props.match.path} path={props.match.path + 'carta/:category_name/:product_url'} component={ProductSheet}/>
            <Route exact basename={props.match.path} path={props.match.path + 'tartas'} component={Cakes}/> 
            <Route exact basename={props.match.path} path={props.match.path + 'tartas/:cake_name'} component={CakesList}/>      
            <Route exact basename={props.match.path} path={props.match.path + 'sobre'} component={About}/>        
            <Route exact basename={props.match.path} path={props.match.path + 'contacto'} component={Contact}/>   
            <Route exact basename={props.match.path} path={props.match.path + 'servicios'} component={Services}/> 
        </Layout>
        

    )
}

export default View