import React from 'react'
import styled from 'styled-components'
import { Nav, Navbar, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import logo from '../../../assets/img/logo.svg'


const header = () => {

    const menu = [
        {
            name: "INICIO",
            key: "1",
            link: "/"
        },
        {
            name: "NUESTRA CARTA",
            key: "2",
            link: "/carta"
        },
        {
            name: "CONTACTO",
            key: "4",
            link: "/contacto"
        }

    ]
    return (
        <Header>
            <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top">
                <Container>
                    <NavLink to={'/'}>
                        <Logo>
                            <img
                                alt="Bombocado"
                                src={logo}
                            />
                            <div className="logo-text">BOMBOCADO</div>
                        </Logo>
                    </NavLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            {menu.map((item, i) => (
                                <NavLink exact={true} to={item.link} key={i} >
                                    <Nav.Link as="div" eventKey={item.key}>{item.name}</Nav.Link>
                                </NavLink>
                            ))}
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Header>
    )
}

export default header

const Header = styled.div`
    
    a {
        text-decoration: none;               
    }

    .navbar{       
        background-color: #db3934;
    }

    .navbar-brand{
        color: #FFF;
        font-size: 1.5em;        
    }
    
    .navbar-text{
        color: #FFF;
        font-size: 1em;
        padding: 0px;
    }

    .navbar-toggler{
        padding: 0px;
        border: none;              
    }
    
    .navbar-toggler:focus{
        outline: none;
        border: none;        
    }    

    .nav-link{
        font-size: 1.2em;
        margin-left: 20px;
        //font-weight: bold; 
        :hover{
        color: #084c61 !important; 
        //font-weight: bold;        
        }            
    }  
    
    .navbar-nav .active .nav-link {
        //color: #084c61;
        //font-weight:bold;
    }

    .navbar-nav .nav-link{
        color: #FFF;
    }

    .navbar-toggler-icon {        
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

`
const Logo = styled.div`
stroke: #db3934;
display: flex;
align-items: center;
.logo-text{
   margin-left: 0.3em;
    color: #FFF;
    font-size: 1.6em;
}
`