import React, { useState, useEffect } from 'react'
import { listIngredients, createIngredient, deleteIngredient } from '../../../services/admin'
import Loading from '../../../components/loading'
import { Container, Row, Col, Tab, Form, Button, InputGroup, Nav } from 'react-bootstrap'
import { BiTrash, BiEdit, BiSearchAlt2, BiSave } from 'react-icons/bi'
import history from '../../../config/history'
import Swal from 'sweetalert2'
import styled from 'styled-components'


const IngredientsList = () => {
    const [ingredients, setIngredients] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({})
    const [updateList, setUpdateList] = useState()
    const [query, setQuery] = useState("")


    useEffect(() => {
        setUpdateList(false)
        let getIngredients = async () => {
            setIsLoading(true)
            setForm({
                uom: ""
            })
            const ing = await listIngredients()
            setIngredients(ing.data)
            setIsLoading(false)
        }
        getIngredients()
        return () => getIngredients = () => {
        }
    }, [updateList])


    const handleChange = (attr) => {
        const { name, value } = attr.target
        setForm({
            ...form,
            [name]: value,
        })
    }

    const isFormValid = () =>
        form.name
        && form.brand
        && form.supplier
        && form.qty
        && form.uom !== ""
        && form.price

    const submitIngredient = async (e) => {
        e.preventDefault()
        const message = (type, message) =>
            Swal.fire({
                toast: true,
                position: 'bottom',
                width: '20rem',
                icon: type || "success",
                title: message,
                showConfirmButton: false,
                timer: 2500,
            })

        setIsLoading(true)
        try {
            await createIngredient(form)
            message('success', `Ingrediente creado con éxito`)
            setIsLoading(false)
            setForm({
                uom: ""
            })
            setUpdateList(true)
        } catch (error) {
            message('error', `Ha ocurrido un error`)
            setIsLoading(false)
            setForm({
                uom: ""
            })
        }
    }

    const removeIngredient = async (ing) => {
        const message = (type, message) => Swal.fire({
            toast: true,
            position: 'bottom',
            width: '20rem',
            icon: type || '',
            title: message || '',
            showConfirmButton: false,
            timer: 2500
        })

        Swal.fire({
            title: `¿Deseas eliminar el ingrediente ${ing.name}? `,
            text: `Esta acción puede afectar a las recetas`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Eliminar`,
            cancelButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteIngredient(ing._id)
                    .then(() => {
                        setUpdateList(true)
                        message('success', `Ingrediente eliminado con éxito.`)
                    })
                    .catch(() => message('error', `Ha ocurrido un error`))
            }
        })
    }


    return (
        <>
            <Content>
            <Tab.Container defaultActiveKey="first">
                <Container className="pt-4 pb-4">
  <Row>
  
    <Col xs={6} md={2} xl={2}>
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="first">Lista</Nav.Link>
        </Nav.Item>        
      </Nav>
    </Col>
    <Col xs={6} md={2} xl={2}>
      <Nav variant="pills" className="flex-column">        
        <Nav.Item>
          <Nav.Link eventKey="second">Nuevo</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    
    <Col sm={12} className="pt-3">
      <Tab.Content>
        <Tab.Pane eventKey="first">
        <Row className="align-items-center">
                            <Col xs={12} md={6} xl={6} className="no-gutters">
                                <InputGroup>
                                    <InputGroup.Text className="mb-2"><BiSearchAlt2 /></InputGroup.Text>
                                    <Form.Control
                                        autoComplete="off"
                                        id="search"
                                        type="search"
                                        className="mb-2"
                                        placeholder="Buscar ingrediente"
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value.toLowerCase())}
                                    />
                                </InputGroup>                                
                            </Col>                            
                        </Row>


          
        </Tab.Pane>
        <Tab.Pane eventKey="second">
        <Row className="align-items-center">
                            <Col xs={12} md={4} xl={4} >
                                <Form.Label>Ingrediente</Form.Label>
                                <Form.Control
                                    autoComplete="__away"
                                    className="mb-4"
                                    id="inlineFormInput"
                                    placeholder="Nombre del ingrediente"
                                    name="name"
                                    value={form.name || ""}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Form.Label>Marca</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    className="mb-4"
                                    placeholder="Marca del ingrediente"
                                    name="brand"
                                    value={form.brand || ""}
                                    onChange={handleChange}
                                />

                            </Col>
                            <Col xs={12} md={4} xl={4}>
                            <Form.Label>Proveedor</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    className="mb-4"
                                    placeholder="Proveedor"
                                    name="supplier"
                                    value={form.supplier || ""}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                                <Form.Label>Cantidad del envase </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    className="mb-4"
                                    placeholder="Cantidad gr/ml/und"
                                    type="number"
                                    name="qty"
                                    value={form.qty || ""}
                                    onChange={handleChange}
                                    min="0"
                                />
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                            <Form.Label>UOM (gr/ml/und) </Form.Label>
                                <Form.Control                                
                                    as="select"
                                    name="uom"
                                    onChange={handleChange}
                                    value={form.uom}
                                    className="custom-select mb-4"
                                    custom          
                                >
                                    <option value="">Seleccionar UOM</option>
                                    <option value="gr">gr</option>
                                    <option value="ml">ml</option>
                                    <option value="und">und</option>
                                </Form.Control>
                                
                                
                            </Col>
                            <Col xs={12} md={4} xl={4}>
                            <Form.Label>Precio </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    className="mb-4"
                                    placeholder="€"
                                    type="number"
                                    name="price"
                                    value={form.price || ""}
                                    onChange={handleChange}
                                    min="0"
                                />
                            </Col>
                            
                        </Row>
                        <Row className="justify-content-end">
                            <Col xs={12} md={4} xl={2} className="d-grid gap-2">                            
                                <Button type="submit" className="mt-2" onClick={submitIngredient} disabled={!isFormValid()}>
                                    <BiSave className="icon-btn"/> Guardar
                                </Button>
                            </Col>
                            </Row>
        
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
  </Container>
</Tab.Container>
            
            
                    <Container>
                    {isLoading
                ? <Loading />
                :
                <>
                        
                        {ingredients
                            .filter(ing => ing.name.toLowerCase().includes(query))
                            .map((filtered, i) => (

                                <RowIngredient className="mb-3" key={i}>
                                    <Col xs={12} md={12} xl={12}>

                                    <div className="ingredient-title">
                                        <div className="ingredient-name">{filtered.name}</div>
                                        <div className="actions">
                                            <BiEdit className="icon" onClick={() => history.push(`ingredientes/${filtered._id}/editar`)} />
                                            <BiTrash className="icon" onClick={() => removeIngredient(filtered)} />
                                        </div>
                                    </div>
                                    </Col>

                                    <Col className="lines" xs={6} md={4} xl={4}>
                                        <p>{filtered.brand}</p>
                                    </Col>

                                    <Col xs={6} md={2} xl={2}>
                                        <p>{filtered.qty} {filtered.uom}</p>
                                    </Col>
                                    <Col xs={6} md={2} xl={2}>
                                        <p>{filtered.price.toFixed(2)}€</p>
                                    </Col>
                                    <Col xs={6} md={2} xl={2}>
                                        <p>({filtered.priceByQty.toFixed(4)}/{filtered.uom})</p>
                                    </Col>
                                    <Col xs={12} md={2} xl={2}>
                                        <p>{filtered.supplier}</p>
                                    </Col>
                                    
                                </RowIngredient>


                            ))}

</>

}

                    </Container>

                    
                
            </Content>
        </>
    )
}

export default IngredientsList

const Content = styled.div`

background-color: #ffffff;
border: 1px solid #dee2e6;
border-radius: .25rem;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #084c61;
}

.no-gutters {
    padding: 0;
}

.nav-tabs {
    :active {
        color: blue;
    }
}

.nav-link {
    font-weight: 600;
    font-size: 1.1;
    color: #888888;     
    //border: 1px solid #dee2e6;
    text-align: center;
    
}

.nav-link.active {
    font-weight: 600;
    background-color: #dee2e6;  
    color: #084c61;
    border: none;
    
}

.tab-content{
    padding: 1rem 1rem;
}

.custom-select {
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #6c757d;
    padding: .375rem .75rem;
    width: 100%;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {   
  border-color: #86b7fe;
  box-shadow:  0 0 10px rgba(134, 183, 254);
  outline: 0 none;
}
}

.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}

.icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
}

`

const RowIngredient = styled(Row)`

p {
    color:#888888;
    padding-left: .4rem;
    margin-top: .4rem;
    margin-bottom: 0;

}
.ingredient-title{
    padding: .3rem;
    display:flex;    
    flex-direction: row;
    justify-content: space-between;
    //background-color: #dee2e6;       
    border-top: 1px solid #dee2e6;
    
}

.ingredient-name {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.actions {
    flex: none;
}
.icon {
    color: #084c61;
    font-size: 1.2rem;
    margin-left: 7px;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
}


`