import axios from 'axios'
import { getToken, removeToken } from './auth'
import history from './history'

const localUrlApi = 'http://localhost:3001'
//
const http = axios.create({
    baseURL: process.env.NODE_ENV ==='development'
    ? localUrlApi
    : process.env.REACT_APP_API
})

http.defaults.headers['Content-type'] = 'application/json'

if (getToken()) {
    http.defaults.headers["x-auth-token"] = getToken()
}

http.interceptors.response.use(
    response => response,
    error => {
        const {response: { status } } = error

        if (error.message === 'Network error' && !error.message) {
            alert('Network error')
        }

        switch (status) {
            case 401 :
                console.log('Token inválido')
                removeToken()
                history.push('/login')
                break
            case 500 :
                history.push('/')
                break
            default:
                console.log(status, `Ha ocurrido un error ${status}`)
                break
        }
        return Promise.reject(error)
    }
)


export default http