import React, { useState, useEffect, useRef  } from 'react'
import { listProducts, listCategory, addProduct } from '../../../services/admin'
import Loading from '../../../components/loading'
import BtnSave from '../../../components/layout/admin/buttons/btnSave'
import { Container, Row, Col, Image, Tab, Nav, InputGroup, Form, Button, ButtonGroup, ToggleButton } from 'react-bootstrap'
import { FaCheck, FaTimes } from 'react-icons/fa'

import { BiSearchAlt2 } from 'react-icons/bi'
import { RiImageAddLine } from 'react-icons/ri'
import Swal from 'sweetalert2'
import styled from 'styled-components'

import { Link } from 'react-router-dom'


const ProductsList = (props) => {
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({})
    const [updateList, setUpdateList] = useState(false)
    const [query, setQuery] = useState("")
    const fileInput = useRef(null)

    useEffect(() => {
        setUpdateList(false)        
        setForm({
            category: ""
        })
        let getProducts = async () => {
            setIsLoading(true)
            const prd = await listProducts()
            setProducts(prd.data)
            const category = await listCategory()
            setCategories(category.data)
            setIsLoading(false)
        }
        getProducts()

        return () => getProducts = () => {
            //cleanup
        }
    }, [updateList])

    
    const handleChange = (attr) => {
        const { name, value, checked } = attr.target
        const isCheck = name === "status"
        if (name === "photo") {
            setForm({
              ...form,
              [name]: value,
              photo: attr.target.files[0],
              photoName: attr.target.files[0].name,
              status: false
            })
          } else {
            setForm({
              ...form,
              [name]: isCheck ? checked : value
              
            })
          }
          //return
    }
    
    const isFormValid = () =>
        form.title
        && form.category !== ""
        && form.price
        && form.url
        && form.photo

    const clearForm = () => {
        setForm({
            title: "",
            category: "",
            status: false,
            highlight: false,
            //photo: "",
            photoName: ""
            });
        };

    const clearFile = () => {
        let file = document.getElementById('photo-upload')
        file.value = ""
    }

    const submitProduct = async (e) => {
        e.preventDefault()
        const message = (type, message) =>
          Swal.fire({
            toast: true,
            position: 'bottom',
            width: '20rem',
            icon: type || "success",
            title: message,
            showConfirmButton: false,
            timer: 2500,
          })
    
        setIsLoading(true)        
    
        let data = new FormData()
        Object.keys(form)
                .forEach(key => data.append(key, form[key]))
                if (typeof form.photo === "string") {                
                    data.delete('photo')
                }         
    
        const config = {
    
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
        setIsLoading(true)
        try {
            await addProduct(data, config)     
            clearForm()   
            clearFile()              
            message('success', `Producto creado con éxito`)
            setUpdateList(true)
            setIsLoading(false)                    
            
        } catch (error) {
            message('error', `Ha ocurrido un error`)
            setIsLoading(false)            
        }        
      }
     
    return (
        <>
            <Content>
                <Tab.Container defaultActiveKey="first">
                    <Container className="pt-4 pb-4">
                        <Row>
                            <Col xs={6} md={2} xl={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Lista</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xs={6} md={2} xl={2}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Nuevo</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={12} className="pt-3">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row className="align-items-center">
                                            <Col xs={12} md={6} xl={6} className="no-gutters">
                                                <InputGroup>
                                                    <InputGroup.Text className="mb-2"><BiSearchAlt2 /></InputGroup.Text>
                                                    <Form.Control
                                                        autoComplete="off"   
                                                        id="search"
                                                        type="search"
                                                        className="mb-2"
                                                        placeholder="Buscar producto"
                                                        value={query}
                                                        onChange={(e) => setQuery(e.target.value.toLowerCase())}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            <Col xs={12} md={3} xl={3} >
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    className="mb-3"
                                                    type="text"
                                                    placeholder="Nombre del producto"
                                                    name="title"
                                                    value={form.title || ""}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={12} md={3} xl={3}>
                                                <Form.Label>Categoría</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="category"
                                                    onChange={handleChange}
                                                    value={form.category}
                                                    className="custom-select mb-3"
                                                    custom
                                                >
                                                    <option value="">Seleccionar categoría</option>
                                                    {categories.map((catg, i) => (
                                                        <option key={i} value={catg._id}>
                                                            {catg.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Col>
                                            <Col xs={12} md={3} xl={3}>
                                                <Form.Label>Precio</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    className="mb-3"
                                                    placeholder="Precio €"
                                                    type="number"
                                                    name="price"
                                                    value={form.price || ""}
                                                    onChange={handleChange}
                                                    min="0"
                                                />
                                            </Col>
                                            <Col xs={12} md={3} xl={3}>
                                                <Form.Label>URL personalizada</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    className="mb-3"
                                                    placeholder="friendly-url"
                                                    type="text"
                                                    name="url"
                                                    value={form.url || ""}
                                                    onChange={handleChange}
                                                    min="0"
                                                />
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col xs={12} md={6} xl={6}>
                                                <Form.Label>Descripción</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    className="mb-3"
                                                    placeholder="Descripción"
                                                    type="text"
                                                    name="description"
                                                    value={form.description || ""}
                                                    onChange={handleChange}
                                                    min="0"
                                                />
                                                <Form.Label>Descripción completa</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    as="textarea"
                                                    rows={2}
                                                    className="mb-3"
                                                    placeholder="Descripción completa"
                                                    name="complete_description"
                                                    value={form.complete_description || ""}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={12} md={6} xl={6} className="d-flex flex-column">                                                
                                                <Row>
                                                <Col xs={6} md={6} xl={6}>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Group className="d-grid gap-2">
                                                    <ButtonGroup toggle className="mb-4">
                                                        <ToggleButton
                                                            type="checkbox"
                                                            variant={Boolean(form.status) ? "outline-success" : "outline-danger"}
                                                            name="status"
                                                            onChange={handleChange}
                                                            checked={Boolean(form.status)}
                                                            className="custom-checkbox"
                                                        >
                                                            {Boolean(form.status) ? <><FaCheck className="icon-btn"/> Activo </> : <><FaTimes className="icon-btn"/> Inactivo</>}
                                                        </ToggleButton>
                                                    </ButtonGroup>
                                                </Form.Group>
                                                </Col>
                                                <Col xs={6} md={6} xl={6}>                                                    
                                                <Form.Label>Foto</Form.Label>   
                                                <Form.Group className="d-grid gap-2">                                            
                                                <input ref={fileInput} name="photo" id="photo-upload" type="file" onChange={handleChange} className="custom-file" />
                                                <Button variant="outline-secondary"  className="mb-4" onClick={() => fileInput.current.click()} >
                                                       <RiImageAddLine className="icon-btn"/> Seleccionar
                                                    </Button>
                                                    </Form.Group>
                                                </Col>                                                
                                                </Row>
                                                <Row>
                                                    <Col className="justify-content-end">
                                                        <p>{form.photoName}</p>
                                                    </Col>                                                    
                                                </Row>
                                               <Row className="mt-auto justify-content-end">
                                                <Col xs={12} md={6} xl={6} className="mb-3">
                                                    <BtnSave onClick={submitProduct} disabled={!isFormValid()}/>                                                    
                                                </Col>
                                                </Row>                                                
                                            </Col>
                                            </Row>               
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Container>
                </Tab.Container>
                <Container>
                    {isLoading
                        ? <Loading />
                        : <>
                            {products
                                .filter(prd => prd.title.toLowerCase().includes(query))
                                .map((filtered, i) => (
                                    <RowProduct key={i} className="pt-3 pb-3">
                                        <Link to={`productos/${filtered._id}`}>
                                        <Col xs={3} md={1} xl={1}>
                                            <Image src={filtered.photo} className={filtered.status === true ? "" : "img-inactive"} alt="product" fluid />
                                        </Col>
                                        <Col xs={9} md={11} xl={11}>
                                            <div className="product-info">
                                                <Col xs={12} md={7} xl={7} className="product-name">                                                    
                                                        {filtered.title}                                                    
                                                </Col>
                                                <Col xs={8} md={4} xl={4}>
                                                    {filtered.status === true
                                                        ? <><FaCheck/> Producto activo</>
                                                        : <><FaTimes/> Producto inactivo</>
                                                    }
                                                </Col>
                                                <Col xs={4} md={1} xl={1}>{filtered.price}€</Col>        
                                            </div>
                                        </Col>
                                        </Link>
                                    </RowProduct>
                                ))}
                        </>
                    }
                </Container>
            </Content>
        </>
    )
}



export default ProductsList

const Content = styled.div`
background-color: #ffffff;
border: 1px solid #dee2e6;
border-radius: .25rem;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #084c61;
}

.no-gutters {
    padding: 0;
}

.nav-tabs {
    :active {
        color: blue;
    }
}

.nav-link {
    font-weight: 600;
    font-size: 1.1;
    color: #888888;     
    //border: 1px solid #dee2e6;
    text-align: center;
    
}

.nav-link.active {
    font-weight: 600;
    background-color: #dee2e6;  
    color: #084c61;
    border: none;
    
}

.tab-content{
    padding: 1rem 1rem;
}

.custom-select {
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #6c757d;
    padding: .375rem .75rem;
    width: 100%;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {   
  border-color: #86b7fe;
  box-shadow:  0 0 10px rgba(134, 183, 254);
  outline: 0 none;
}
}

.custom-checkbox{
    input {
        display: none;
    }
}

.custom-file{
    display: none;
  //color: transparent;
}

.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}

.icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
}

`

const RowProduct = styled(Col)`
display: flex;
flex-wrap: wrap;
border-top: 1px solid #dee2e6;

a {
    border-radius: .25rem;
    border: 1px solid transparent;
    display: flex;
    font-size: 1.1rem;
    text-decoration: none;
    color: transparent;
    transition: all 0.15s ease-in-out 0s; 
    width: 100%;

    :hover{
    background-color: #f8f8fa;
    border: 1px solid transparent;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    transition: all 0.15s ease-in-out 0s;   

  }
}

.product-info {
  color:#888888;
  height: 100%;
  padding-left: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product-name {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    overflow: hidden;    
    text-overflow: ellipsis;
    white-space: nowrap;      
}

.actions {
    flex: none;
    text-align: right;
}
.icon {
    color: #084c61;
    font-size: 1.2rem;
    margin-left: 7px;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
}

`
