import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import styled from 'styled-components'
import { ProductSheet } from '../../../services/portal'
import Loading from '../../../components/loading'
import { useParams } from 'react-router-dom'
import TitleSubPage from '../../../components/layout/portal/titleSubPage'
import history from '../../../config/history'

const Sheet = () => {

    const [product, setProduct] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [emptyData, setEmptyData] = useState()
    const { product_url } = useParams()

    useEffect(() => {
        window.scrollTo(0,0)
        let get = async () => { 
            setIsLoading(true)
            const prd = await ProductSheet(product_url) 
            if (prd.data.length === 0) {
                setEmptyData(true)
                setIsLoading(false)
            } else {
                setProduct(prd.data)
                setIsLoading(false)
                
            }
            
        }        
            
            get()       
        
        return () => get = () => {
            //cleanup
        }
    }, [product_url])

    return(
        <>
        {isLoading
        ? <Loading/>
        : <Content>
            <TitleSubPage title={product.title} onClick={() => history.goBack()} />
           
            <Row> 
                {emptyData 
                ? <p> El producto no existe :(</p>
                : <>
                <Row>
            <Col xs={12} md={5} xl={5} className="mb-4">
                <Image src={product.photo} fluid/>            
            </Col>
            <Col xs={12} md={7} xl={7} className="d-flex">
                <div className="product-info align-self-center">
                    <p>{product.description}</p>
                    <p className="price">{product.price}€</p>

                </div>
            </Col>
            </Row>
               </> }
            </Row>
            </Content>
        }
        </>
    )
}

export default Sheet
const Content = styled(Container)`
//margin-top: 2rem;
margin-bottom: 2rem;

.product-info {
    text-align: center;
}

p {
    color: #084c61;  
    font-size: 1.1rem;     
            
}

.price {
    font-weight: 600;
    font-size: 1.2rem;
    text-align: right;
}

h2 {
    color: #084c61;
    font-size: 2rem;
    font-weight: 600;   
    text-align: center;
}
`


