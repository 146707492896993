import React from 'react'
import Hello from './hello'
import InfoCards from './infoCards'



const Dash = () => {
    return (
        <>
        
            <Hello/>            
            <InfoCards/>
                
            
            
        </>
    )
}

export default Dash



