import React, { Suspense, lazy, useEffect } from 'react'
import Loading from '../../../components/loading'
import { wakeUp } from '../../../services/portal'
const Carousel = lazy(() => import('./carousel1'))
const ContentInfo = lazy(() => import('./information'))
const Cakes = lazy(() => import('./cakes'))

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        let get = async () => {
            await wakeUp()            
        }
        get()

      }, [])
      
    return (
        <>
                    <Suspense fallback={<Loading/>}>   
                    <Carousel/>
                    <ContentInfo/>
                    <Cakes/>
                    </Suspense>
                    
                                              
            
           
        </>
    )
}



export default Home

