import  React from 'react'
import TitleSubPage from '../../../components/layout/admin/titleSubPage'
import ProductData from './productData'
import Ingredients from './ingredients'
import Directions from './directions'
import Remarks from './remarks'
import history from '../../../config/history'
import styled from 'styled-components'
import { Container, Card, Accordion} from 'react-bootstrap'
import ContextAwareToggle from '../../../components/layout/admin/accordion'



const Products = () => {  
  return (
    <>
      <TitleSubPage title="Detalles del producto" onClick={() => history.goBack()} />

      <ContentProduct>
        <ProductData />
        <Container>
        <Accordion>      
      <Card className="mb-4">
        <Card.Header>
          <ContextAwareToggle eventKey="0">Ingredientes</ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
          <Ingredients />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="mb-4">
        <Card.Header>
          <ContextAwareToggle eventKey="1">Preparación</ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
        <Card.Body>
          <Directions/>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className="mb-4">
        <Card.Header>
          <ContextAwareToggle eventKey="2">Observaciones</ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
          <Remarks />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>          
        </Container>
      </ContentProduct>
    </>
  )
}



export default Products
const ContentProduct = styled.div`
background-color: #ffffff;
border: 1px solid #dee2e6;
border-radius: .25rem;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.card{
    .card-header{
      background-color: #f8f8fa ;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 0px;
        h4 {           
            color: #084c61;
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0;
        }
        
    }

    
}

`