import http from '../config/http'



// POST
const addCategory = (data) => http.post(`/category`, data)
const addProduct = (data, config = {}) => http.post(`/product`, data, config)
const addBanner = (data, config = {}) => http.post(`/banner`, data, config)
const addIngredient = (data) => http.post(`/recipe`, data)
const createIngredient = (data) => http.post(`/ingredient`, data)
const addStep = (data) => http.post(`/direction`, data)
const addRemark = (data) => http.post(`/remark`, data)

// GET
const listCategory = () => http.get(`/category`)
const listProducts = () => http.get(`/product`)
const listBanner = () => http.get(`/banner`)
const listIngredients = () => http.get(`/ingredient`)
const listCardsInfo = () => http.get(`/infocards`)


// GET by id
const showCategory = (id) => http.get(`/category/${id}`)
const showProduct = (id) => http.get(`/product/${id}`)
const showBanner = (id) => http.get(`/banner/${id}`)
const showIngredients = (id) => http.get(`/recipe/${id}`)
const showIngredient = (id) => http.get(`/ingredient/${id}`)
const showSteps = (id) => http.get(`/direction/${id}`)
const showRemarks = (id) => http.get(`/remark/${id}`)

// UPDATE
const updateCategory = (id, data) => http.patch(`/category/${id}`, data)
const updateProduct = (id, data) => http.patch(`/product/${id}`, data)
const updateBanner = (id, data) => http.patch(`/banner/${id}`, data)
const updateIngredient = (id, data) => http.patch(`/ingredient/${id}`, data)

// DELETE by Id
const deleteCategory = (id) => http.delete(`/category/${id}`)
const deleteProduct = (id) => http.delete(`/product/${id}`)
const deleteBanner = (id) => http.delete(`/banner/${id}`)
const deleteRecipeIngredient = (id) => http.delete(`/recipe/${id}`)
const deleteIngredient = (id) => http.delete(`/ingredient/${id}`)
const deleteStep = (id) => http.delete(`/direction/${id}`)
const deleteRemark = (id) => http.delete(`/remark/${id}`)



export {
    addCategory,
    addProduct,
    addBanner,
    addIngredient,
    addStep,
    addRemark,
    createIngredient,
    listCategory,
    listProducts,
    listBanner,
    listIngredients,
    listCardsInfo,
    showCategory,
    showProduct,
    showBanner,
    showIngredients,
    showIngredient,
    showSteps,
    showRemarks,
    updateCategory,
    updateProduct,
    updateBanner,
    updateIngredient,
    deleteCategory,
    deleteProduct,
    deleteBanner,
    deleteRecipeIngredient,
    deleteIngredient,
    deleteStep,
    deleteRemark
    
}