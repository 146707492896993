import React from 'react'
import { Route } from 'react-router-dom'

import Layout from '../../components/layout/admin'

//import Banner from './banner'
import Categories from './categories'
import Dashboard from './dashboard'
import Ingredients from './ingredients'
import Products from './products'
import ProductSheet from './products/productSheet'
import FormProduct from './products/formProduct'
import FormIngredient from './ingredients/formIngredient'
import FormCategory from './categories/formCategory'

const admin = (props) => {
    return (
        <Layout>
            <Route exact basename={props.match.path} path={props.match.path + '/'} component={Dashboard}/>
            {/*<Route exact basename={props.match.path} path={props.match.path + '/banner'} component={Banner}/>*/}
            <Route exact basename={props.match.path} path={props.match.path + '/categorias'} component={Categories}/>
            <Route exact basename={props.match.path} path={props.match.path + '/nueva-categoria'} component={FormCategory}/>
            <Route exact basename={props.match.path} path={props.match.path + '/categorias/:_id/editar'} component={FormCategory}/>
            <Route exact basename={props.match.path} path={props.match.path + '/productos'} component={Products}/>  
            <Route exact basename={props.match.path} path={props.match.path + '/nuevo-producto' } component={FormProduct}/>
            <Route exact basename={props.match.path} path={props.match.path + '/productos/:_id' } component={ProductSheet}/>
            <Route exact basename={props.match.path} path={props.match.path + '/productos/:_id/editar' } component={FormProduct}/>            
            <Route exact basename={props.match.path} path={props.match.path + '/ingredientes' } component={Ingredients}/> 
            <Route exact basename={props.match.path} path={props.match.path + '/nuevo-ingrediente' } component={FormIngredient}/>
            <Route exact basename={props.match.path} path={props.match.path + '/ingredientes/:_id/editar' } component={FormIngredient}/>           
        </Layout>

    )
}

export default admin