import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import styled from 'styled-components'
import { FaWhatsapp, FaInstagram, FaFacebook } from 'react-icons/fa'
import { MdLocationOn, MdPhone } from 'react-icons/md' 
import { AiOutlineShop } from 'react-icons/ai'


const footer = () => {
    return (
        <Footer>
            <Container>
                <FooterInfo>

                    <Row>
                        <Col xs={12} md={4} xl={4} className="no-gutters mt-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>SÍGUENOS</Card.Title>                                
                                <Card.Text>
                                <a href="https://www.instagram.com/bombocado_es" target="_blank" rel="noopener noreferrer" >
                                    <FaInstagram className="card-icon"/>@bombocado_es
                                    </a>
                                </Card.Text>
                                <Card.Text>
                                <a href="https://www.facebook.com/bombocadoES" target="_blank" rel="noopener noreferrer" >
                                    <FaFacebook className="card-icon"/>@bombocadoES
                                    </a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col xs={12} md={4} xl={4} className="no-gutters mt-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>CONTACTO</Card.Title>
                                <Card.Text>
                                <a href="https://wa.me/34602449031?text=Hola%20Bombocado!%20:)" target="_blank" rel="noopener noreferrer" >
                                    <FaWhatsapp className="card-icon"/> 
                                    602 44 90 31
                                    </a>
                                </Card.Text>
                                <Card.Text>
                                    <MdPhone className="card-icon"/>91 948 91 82
                                </Card.Text>                                
                            </Card.Body>
                        </Card>
                        </Col>
                        
                        <Col xs={12} md={4} xl={4}className="no-gutters mt-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>DONDE ESTAMOS</Card.Title>    
                                <Card.Text><AiOutlineShop className="card-icon"/>Mercado de Vicálvaro - local 14</Card.Text>                            
                                <Card.Text><MdLocationOn className="card-icon"/>C/ Velilla, 11 - 28032 - Madrid</Card.Text>   
                            </Card.Body>
                        </Card>                            
                        </Col>
                    </Row>

                </FooterInfo>
                <Row>

                    
                    <FooterCopy>
                    <div>Bombocado delicias caseras hechas con ♥</div>
            </FooterCopy>
                </Row>
            </Container>
        </Footer>

    )
}

export default footer

const Footer = styled.div`
flex-shrink: 0;
background: #db3934;
font-size: 1em;
color: #FFF


`



const FooterInfo = styled.div`

    .no-gutters{
        padding-left: 0px;
        padding-right: 0px;
    }    
    .card{
        border: none;
        background: #db3934;
        align-items: center;

        .card-body{
            padding: 0px;
        }
        
        .card-title{
            text-align: center;  
            color: #ffffff;      
        }

        .card-text{
            color: #ffffff;
            
            font-size: 1em;
            a {                
                text-decoration: none;
                color: #ffffff;

            }
        }

        .card-icon{
            font-size: 1.3em;
            margin-right: 10px;
        }
    }
    

`


const FooterCopy = styled.div`
    padding: 15px;
    width: 100%;
    text-align: left;
    border-top: 2px solid #FFF;
    margin-top: 20px;

    
`