import React from 'react'
import TitlePage from '../../../components/layout/admin/title'
import IngredientsList from './list'

const Ingredients = () => {
    return(
        <>
            <TitlePage title="Ingredientes"/>
            <IngredientsList/>
        </>
        
    )
}

export default Ingredients