import React, { useState, useEffect } from 'react'
import { showProduct, deleteProduct } from '../../../services/admin'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Image} from 'react-bootstrap'
import styled from 'styled-components'
import Loading from '../../../components/loading'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { BiTrash, BiEdit } from 'react-icons/bi'
import Swal from 'sweetalert2'
import history from '../../../config/history'

const Sheet = () => {
    const [product, setProduct] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const { _id } = useParams()
    useEffect(() => {
        window.scrollTo(0,0)
        let get = async () => { 
            setIsLoading(true)
            const prd = await showProduct(_id) 
            setProduct(prd.data)
            setIsLoading(false)
        }        
            get()       
        
        return () => get = () => {
            //cleanup
        }
    }, [_id])

    const removeProduct = async (prd) => {
        const message = (type, message) => Swal.fire({
            toast: true,
            position: 'bottom',
            width: '20rem',
            icon: type || '',
            title: message || '',
            showConfirmButton: false,
            timer: 2500
        })

        Swal.fire({
            title: `¿Deseas eliminar el producto ${prd.title}? `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Eliminar`,
            cancelButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProduct(prd._id)
                    .then(() => {
                        message('success', `Producto eliminado con éxito.`)
                        history.push('/admin/productos')
                        
                    })
                    .catch(() => message('danger', `Ha ocurrido un error`))
            }
        })
    }


    return(
        <>
        {isLoading
        ? <Loading/>
        :               
                <>
                    
                    <Content>
                        <Container className="p-4 pt-0"> 
                        
                            <div className="d-flex justify-content-end mt-2 mb-2">
                            <BiEdit className="icon" onClick={() => history.push(`/admin/productos/${product._id}/editar`)}/>
                            <BiTrash className="icon" onClick={() => removeProduct(product)}/>
                            </div>
                        
                                            
                            <Row>
                                <Col xs={12} md={4} xl={4} className="mb-3">
                                    <Image src={product.photo} thumbnail />
                                </Col>
                                <Col xs={12} md={8} xl={8}>
                                <Row>
                                        
                                        <h4>{product.title}</h4>
                                    </Row>
                                    <Row>
                                        <span className="label-product">Status</span>
                                        <p>{product.status === true ? <><FaCheck /> Producto activo </>: <><FaTimes /> Producto desactivado </>}</p>
                                    </Row>
                                    <Row>
                                        <span className="label-product">Descripción</span>
                                        <p>{product.description}</p>
                                    </Row>
                                    <Row>
                                        <span className="label-product">Descripción completa</span>
                                        <p>{product.complete_description}</p>
                                    </Row>
                                    <Row>
                                        <span className="label-product">URL personalizada</span>
                                        <p>/{product.url}</p>
                                    </Row>
                                    <Row>
                                        <span className="label-product">Precio</span>
                                        <p>{product.price}€</p>
                                    </Row>
                                    
                                </Col>                                

                            </Row>
                            <Row>

                            </Row>
                        </Container>

                    </Content>
                </>
            
            
                        
        }
        
        </>        
        
        
    )
}

export default Sheet

const Content = styled.div`

h4 {
    color: #084c61;
    font-size: 1.5rem;
    font-weight: 600;
}

p {
    color:#888888;
    font-size: 1.1rem;
}

.icon{
    color: #084c61;
    font-size: 1.3rem;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
    margin-left: .4rem;
}

.label-product{
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
}

.dropdown-menu.show{    
        transform: translate3d(-65px, 40px, 0px) !important
    
    
}
.nav{

}
.label {
    color: #000000;
    font-size: 1rem;
    font-weight: 600;
}
`
