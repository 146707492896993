import React, { useState, useEffect, useRef } from 'react'
import { addProduct, updateProduct, listCategory, showProduct } from '../../../services/admin'
import Swal from 'sweetalert2'
import { Row, Col, Form, Button, Container, ButtonGroup, ToggleButton, Image } from 'react-bootstrap'
import TitleSubPage from '../../../components/layout/admin/titleSubPage'
import BtnSave from '../../../components/layout/admin/buttons/btnSave'
import BtnCancel from '../../../components/layout/admin/buttons/btnCancel'
import { useParams, useLocation } from 'react-router-dom'
import history from '../../../config/history'
import Loading from '../../../components/loading'
import { FaCheck, FaTimes } from "react-icons/fa"
import { RiImageAddLine } from "react-icons/ri"
import styled from 'styled-components'

const FormProducts = (props) => {

  const [form, setForm] = useState({})
  const { _id } = useParams()

  const [categories, setCategories] = useState([])
  const [updatePhoto, setUpdatePhoto] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fileInput = useRef(null)
  const isUpdate = useLocation().pathname !== '/admin/nuevo-producto'

  useEffect(() => {
    setIsLoading(true)    
      let getProduct = async () => {
        const category = await listCategory()
        setCategories(category.data)
        if(isUpdate){                 
        const product = await showProduct(_id)        
        setForm(product.data)
        setIsLoading(false)
          
        }else{
          //setForm({})
          setUpdatePhoto(true)
          setIsLoading(false)
        }           
    }
    getProduct() 

    return () =>  getProduct =  () => {
      //cleanup
    }
  }, [_id, isUpdate])



  const handleChange = (attr) => {
    const { name, value, checked } = attr.target
    const isCheck = name === "status"
    if (name === "photo") {
        setForm({
          ...form,
          [name]: value,
          photo: attr.target.files[0],
          photoName: attr.target.files[0].name,
          status: false
        })
      } else {
        setForm({
          ...form,
          [name]: isCheck ? checked : value
          
        })
      }
      //return
}

  
  const typeReq = (data) => isUpdate ? updateProduct(form._id, data) : addProduct(data)

  const isFormValid = () =>
  form.title
  && form.category !== ""
  && form.price
  && form.url
  && form.photo

const clearForm = () => {
  setForm({
      title: "",
      category: "",
      status: false,
      highlight: false,
      //photo: "",
      photoName: ""
      });
  };

const clearFile = () => {
  let file = document.getElementById('photo-upload')
  file.value = ""
}

const submitProduct = async (e) => {
  e.preventDefault()
  const message = (type, message) =>
    Swal.fire({
      toast: true,
      position: 'bottom',
      width: '20rem',
      icon: type || "success",
      title: message,
      showConfirmButton: false,
      timer: 2500,
    })

  setIsLoading(true)


    let data = new FormData()
    Object.keys(form)
      .forEach(key => data.append(key, form[key]))
    if (typeof form.photo === "string") {
      data.delete('photo')      
    }


    const config = {

      headers: {
        "Content-type": "multipart/form-data",
      },
    }
    setIsLoading(true)
    
  
    typeReq(data, config)
      .then(() => {      
        message('success', `Producto ${isUpdate ? "actualizado" : "creado" } con éxito`)
        history.push('/admin/productos')
        clearForm()
        clearFile() 
        setIsLoading(false) 
      })
      .catch(() => message('error', `Ha ocurrido un error`))
      setIsLoading(false) 
  }; 

  const removePhoto = () => {
    setUpdatePhoto(true)    
    setForm({
      ...form,
      photo: "",
    })
  }


  return (
    <>
      <TitleSubPage title={isUpdate ? "Editar producto" : "Nuevo producto"} onClick={() => history.goBack()}/>
      
        {isLoading
          ? <Loading />
          :
          <Content>
          <Container className="p-4">
            <Form>
              <Row>
                <Col xs={12} md={4} xl={4}>
                <Col xs={12} md={12} xl={12}>
                      <Form.Label>Foto</Form.Label>
                      <Form.Group className="d-grid gap-2">
                        {!updatePhoto ? (
                          <>
                            <Image src={form.photo} thumbnail />
                            <ActionPhoto onClick={removePhoto}><FaTimes className="icon" />Eliminar Foto</ActionPhoto>
                          </>
                        ) : (
                          <>
                            <input ref={fileInput} name="photo" id="photo-upload" type="file" onChange={handleChange} className="custom-file" />
                            <Button variant="outline-secondary" className="mb-4" onClick={() => fileInput.current.click()} >
                              <RiImageAddLine className="icon-btn" /> Seleccionar
                            </Button>
                          </>
                        )}
                      </Form.Group>
                      <Row>
                    <Col className="justify-content-end">
                      <p>{form.photoName}</p>
                    </Col>
                    
                  </Row>
                    </Col>
                </Col>
                <Col xs={12} md={8} xl={8}>
                  <Row>
                  <Col xs={12} md={4} xl={3}>
                      <Form.Label>Status</Form.Label>
                      <Form.Group className="d-grid gap-2">
                        <ButtonGroup toggle className="mb-4">
                          <ToggleButton
                            type="checkbox"
                            variant={Boolean(form.status) ? "outline-success" : "outline-danger"}
                            name="status"
                            onChange={handleChange}
                            checked={Boolean(form.status)}
                            className="custom-checkbox"
                          >
                            {Boolean(form.status) ? <><FaCheck className="icon-btn" /> Activo </> : <><FaTimes className="icon-btn" /> Inactivo</>}
                          </ToggleButton>
                        </ButtonGroup>

                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                  <Col xs={12} md={6} xl={6} >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className="mb-4"
                    type="text"
                    placeholder="Nombre del producto"
                    name="title"
                    value={form.title || ""}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6} xl={6}>
                  <Form.Label>Categoría</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    onChange={handleChange}
                    value={form.category}
                    className="custom-select mb-4"
                    custom
                  >
                    <option value="">Seleccionar categoría</option>
                    {categories.map((catg, i) => (
                      <option key={i} value={catg._id}>
                        {catg.name}
                      </option>
                    )
                    )}
                  </Form.Control>
                </Col>                
                  </Row>
                  <Row>
                  <Col xs={12} md={12} xl={12}>
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className="mb-4"
                    placeholder="Descripción"
                    type="text"
                    name="description"
                    value={form.description || ""}
                    onChange={handleChange}
                    min="0"
                  />
                  </Col>
                  <Col xs={12} md={12} xl={12}>
                  <Form.Label>Descripción completa</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    rows={3}
                    className="mb-4"
                    placeholder="Descripción completa"
                    name="complete_description"
                    value={form.complete_description || ""}
                    onChange={handleChange}
                  />
                  </Col>
                  </Row>
                  <Row>
                  
                  <Col xs={12} md={6} xl={6}>
                  <Form.Label>URL personalizada</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className="mb-4"
                    placeholder="friendly-url"
                    type="text"
                    name="url"
                    value={form.url || ""}
                    onChange={handleChange}
                    min="0"
                  />
                </Col>
                
                    <Col xs={12} md={6} xl={6}>
                  <Form.Label>Precio</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className="mb-4"
                    placeholder="Precio €"
                    type="number"
                    name="price"
                    value={form.price || ""}
                    onChange={handleChange}
                    min="0"
                  />

                </Col>
                  </Row>
                  <Row className="justify-content-end mt-3">
                  <Col xs={12} md={4} xl={4} className="mb-3">
                  <BtnCancel  onClick={() => history.goBack()} />                      
                    </Col>
                    <Col xs={12} md={4} xl={4} className="mb-3">
                      <BtnSave onClick={submitProduct} disabled={!isFormValid()}/>                      
                    </Col>                    
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>
          </Content>
        }
      
    </>
  )
}

export default FormProducts

const Content = styled.div`
background-color: #ffffff;
border: 1px solid #dee2e6;
border-radius: .25rem;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.custom-select {
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #6c757d;
    padding: .375rem .75rem;
    width: 100%;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {   
  border-color: #86b7fe;
  box-shadow:  0 0 10px rgba(134, 183, 254);
  outline: 0 none;
}
}

.custom-checkbox{
    input {
        display: none;
    }
}

.custom-file{
    display: none;
  //color: transparent;
}

.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}

.icon-btn {
    font-size: 1.2rem;
    margin-bottom:.2rem;
}

.actions{
    display: flex;
    justify-content: space-between;
}
`

const ActionPhoto = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: #212529;
    &:hover {
      color: #dc3545;
    }
    .icon {
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
  
`

