import React from 'react'
import TitlePage from '../../../components/layout/admin/title'
import CategoriesList from './list'


import {  Container } from 'react-bootstrap'



const Categories = () => {

       
    return (
        <>
        <Container fluid>
            <TitlePage title="Categorías"/>  
                <CategoriesList />         
        </Container>                
           
        </>
    )
}

export default Categories
