import React from 'react'
import TitlePage from '../../../components/layout/admin/title'
import ProductsList from './list'

const Products = () => {
        
    return (
        <>        
            <TitlePage title="Productos"/>            
            <ProductsList />                            
            
        </>
    )
}

export default Products
