import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

const history = createBrowserHistory()

ReactGA.initialize('UA-201040124-1')
ReactGA.pageview(window.location.pathname + window.location.search)

history.listen((location) => {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
})

export default history