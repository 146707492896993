import React from 'react'
import styled from 'styled-components'

const TitlePage = (props) => {
    return (
        <>                      
                
                    <Title>
                        <h1>{ props.title }</h1>                          
                    </Title>                              
          
        </>
    )
}



export default TitlePage



const Title = styled.div`
          
    color: #084c61 !important; 
    margin-bottom: 1rem;    
   
    h1 {
        font-size: 2em;
        ::first-letter{
        text-transform: uppercase;
             
        }
        //text-transform: uppercase;
        font-weight: 600;
        text-align: left;
    }
    
    
`