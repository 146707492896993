import React, { useState, useEffect } from 'react'
import { showIngredient, updateIngredient, createIngredient } from '../../../services/admin'
import history from '../../../config/history'
import TitleSubPage from '../../../components/layout/admin/titleSubPage'
import BtnSave from '../../../components/layout/admin/buttons/btnSave'
import BtnCancel from '../../../components/layout/admin/buttons/btnCancel'
import { useParams, useLocation } from 'react-router-dom'
import Loading from '../../../components/loading'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import styled from 'styled-components'


const FormEditIngredient = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({})
    const { _id } = useParams()
    const isUpdate = useLocation().pathname !== '/admin/nuevo-ingrediente'

    useEffect(() => {
        setIsLoading(true)
        let getIngredient = async () => {
            if(isUpdate) {
                const ing = await showIngredient(_id)
            setForm(ing.data)
            setIsLoading(false)
            }else {
                setIsLoading(false)
            }
            
        }
        getIngredient()
        return () => getIngredient = () => {
        }
    }, [isUpdate, _id])

    const handleChange = (attr) => {
        const { name, value } = attr.target
        setForm({
            ...form,
            [name]: value
        })
    }

    const typeReq = (data) => isUpdate ? updateIngredient(form._id, data) : createIngredient(data)

    const isFormValid = () =>
        form.name
        && form.brand
        && form.supplier
        && form.qty
        && form.uom !== ""
        && form.price


    const submitIngredient = async () => {        
        const message = (type, message) =>
            Swal.fire({
                toast: true,
                position: 'bottom',
                width: '20rem',
                icon: type || "success",
                title: message,
                showConfirmButton: false,
                timer: 2500,
            })

        setIsLoading(true)
        try {
            await typeReq(form)
            message('success', `Ingrediente ${isUpdate ? "editado" : "creado"} con éxito`)
            setIsLoading(false)
            history.push('/admin/ingredientes')

        } catch (error) {
            message('error', `Ha ocurrido un error`)
            setIsLoading(false)
            setForm({
                uom: ""
            })
        }
    }



    return (
        <>
        <TitleSubPage title={isUpdate ? "Editar ingrediente" : "Nuevo ingrediente"} onClick={() => history.goBack()}/>
                       
                    
                    {isLoading
                        ? <Loading />
                        :                             
                        <Content>
                                <Container className="p-4">
                                        <Form>
                                            <Row className="justify-content-end">
                                            <Col xs={12} md={4} xl={4}>
                                            <Form.Label>Ingrediente</Form.Label>
                                            <Form.Control
                                                className="mb-4"
                                                id="inlineFormInput"
                                                placeholder="Ingrediente"
                                                name="name"
                                                value={form.name || ""}
                                                onChange={handleChange}
                                            />

                                            </Col>
                                            <Col xs={12} md={4} xl={4}>
                                            <Form.Label>Marca</Form.Label>
                                            <Form.Control
                                                className="mb-4"
                                                placeholder="Marca"
                                                name="brand"
                                                value={form.brand || ""}
                                                onChange={handleChange}
                                            />
                                                
                                            </Col>
                                            <Col xs={12} md={4} xl={4}>
                                            <Form.Label>Proveedor</Form.Label>
                                            <Form.Control
                                                className="mb-4"
                                                placeholder="Proveedor"
                                                name="supplier"
                                                value={form.supplier || ""}
                                                onChange={handleChange}
                                            />
                                                
                                            </Col>
                                            <Col xs={12} md={4} xl={4} >
                                            <Form.Label>Cantidad envase</Form.Label>
                                            <Form.Control
                                                className="mb-4"
                                                placeholder="Cantidad envase"
                                                type="number"
                                                name="qty"
                                                value={form.qty || ""}
                                                onChange={handleChange}
                                                min="0"
                                            />
                                                
                                            </Col>
                                            <Col xs={12} md={4} xl={4} >
                                            <Form.Label>gr/ml/und</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="uom"
                                                onChange={handleChange}
                                                value={form.uom}
                                                className="custom-select mb-4"
                                                custom
                                            >
                                                <option value="">...</option>
                                                <option value="gr">gr</option>
                                                <option value="ml">ml</option>
                                                <option value="und">und</option>


                                            </Form.Control>
                                                
                                            </Col>
                                            <Col xs={12} md={4} xl={4} >
                                            <Form.Label>Precio</Form.Label>
                                            <Form.Control
                                                className="mb-4"
                                                placeholder="€"
                                                type="number"
                                                name="price"
                                                value={form.price || ""}
                                                onChange={handleChange}
                                                min="0"
                                            />
                                                
                                            </Col>
                                            
                                            <Col xs={12} md={4} xl={4} className="align-items-center">
                                                <Row className="mt-3">                                                    
                                                    <Col className="d-grid gap-2">
                                                        <BtnCancel onClick={() => history.goBack()}/> 
                                                    </Col>
                                                    <Col className="d-grid gap-2">
                                                    <BtnSave onClick={() => submitIngredient()} disabled={!isFormValid()}/>                                                    
                                                    </Col>
                                                </Row>
                                        
                                            </Col>                                            
                                            </Row>
                                        </Form>
                                        </Container>
                            
                                        </Content>
                    }            
        </>
    )
}

export default FormEditIngredient

const Content = styled.div`
background-color: #ffffff;
border: 1px solid #dee2e6;
border-radius: .25rem;
box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

.form-label {
    color: #000000;
    //font-size: .9rem;
    font-weight: 600;
}

.custom-select {
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #6c757d;
    padding: .375rem .75rem;
    width: 100%;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {   
  border-color: #86b7fe;
  box-shadow:  0 0 10px rgba(134, 183, 254);
  outline: 0 none;
}
}

.actions{
    display: flex;
    justify-content: space-between;
}
`

