import React from 'react'
import ReactDOM from 'react-dom'
import Router from './routes'
import GlobalStyle from './assets/styles/globalStyle'
import CookieConsent from "react-cookie-consent";
import { BiCookie } from 'react-icons/bi'

ReactDOM.render(
  <React.Fragment>
    <GlobalStyle/>
    <Router/>
    <CookieConsent 
    buttonText="Aceptar"
    buttonStyle={{ background: "#084c61", borderRadius: ".25rem", color: "#ffffff", padding: ".375rem .75rem" }}
    style={{
      background: "#F8F8FA",
      color: "#084c61",
      fontSize: "1.1rem",
      border: "1px solid #084c61"
    }}
    >
      <BiCookie style={{ fontSize: "2rem"}}/> <span style={{ fontWeight: "600"}}>Nuestra web usa cookies</span> que nos ayudan a ofrecerte una mejor experiencia de usuario. Si continúas navegando, consideramos que aceptas su uso.
      
      </CookieConsent>
  </React.Fragment>,
  document.getElementById('root')
)
