import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import styled from 'styled-components'
import { listProductsByCategory } from '../../../services/portal'
import Loading from '../../../components/loading'
import { useParams } from 'react-router-dom'
import TitleSubPage from '../../../components/layout/portal/titleSubPage'
import history from '../../../config/history'



const CakesList = (props) => {

    const { cake_name } = useParams()
    const category_title = cake_name.replaceAll('-', ' ')

    const [isLoading, setIsLoading] = useState(false)
    const [emptyData, setEmptyData] = useState()
    const [products, setProducts] = useState([])   

       
     

    //setIsLoading(false)
    //setEmptyData(false)

    const nakedCakeData = { 
        img : "https://bdkn-bucket.s3-eu-west-1.amazonaws.com/img/tartas/chocolate-coco-naked.jpg",
        description : "Nuestra naked cake está hecha con 3 capas de bizcocho de chocolate o de vainilla y 2 capas de relleno que quedan totalmente al descubierto por los laterales. Para la decoración podemos usar frutas, virutas de chocolate, galletas o lo que tú nos sugieras para personalizarla a tu gusto ;)",
        size : "Su tamaño es de aproximadamente 20cm de diámetro y se pueden partir entre 12 y 15 raciones."
       
    }

    const piscina18Data = { 
        img : "https://bdkn-bucket.s3-eu-west-1.amazonaws.com/img/tartas/chocolate-oreo-piscina.jpg",
        description : "Nuestra tarta piscina está hecha con una base de bizcocho de chocolate o de vainilla que se queda totalmente al descubierto. La peculiaridad de esta tarta es que el relleno va en la piscina de la parte superior. Para la decoración podemos usar frutas, virutas de chocolate, galletas o lo que tú nos sugieras para personalizarla a tu gusto ;)",
        size : "El tamaño de la piscina mediana es de aproximadamente 18cm y se pueden partir entre 10 y 12 raciones."
    }

    const piscina14Data = { 
        img : "https://bdkn-bucket.s3-eu-west-1.amazonaws.com/img/tartas/red-velvet-piscina.jpg",
        description : "Nuestra tarta piscina está hecha con una base de bizcocho de chocolate o de vainilla que se queda totalmente al descubierto. La peculiaridad de esta tarta es que el relleno va en la piscina de la parte superior. Para la decoración podemos usar frutas, virutas de chocolate, galletas o lo que tú nos sugieras para personalizarla a tu gusto ;)",
        size : "El tamaño de la piscina pequeña es de aproximadamente 14cm y se pueden partir entre 6 y 8 raciones."
    }

    const cakesData = () => {
        switch (true) {
            case cake_name === 'naked-cake':
                return nakedCakeData
            case cake_name === 'piscina-mediana':
                return piscina18Data
            case cake_name === 'piscina':
                return piscina14Data  
            default:
                return nakedCakeData
                
        }
    }

    const showCakeInfo = cakesData()   
  
    
    useEffect(() => {        
        window.scrollTo(0,0)
        let get = async () => { 
            setIsLoading(true)
            const prd = await listProductsByCategory(cake_name)    
            if (prd.data.length === 0) {
                setEmptyData(true)
                setIsLoading(false)
            } else {
                setProducts(prd.data)
                setIsLoading(false)
                
            }
            
        }        
            get()       
        
        return () => get = () => {
            //cleanup
        }
    }, [cake_name])
    
    
         


    return (
        <>    
        <Content>      
        <TitleSubPage title={category_title} onClick={() => history.goBack()} />
        {isLoading
        ? <Loading/>
        : 
        <Row>
            <Col xs={12} md={4} xl={4}>
                <Image src={showCakeInfo.img} fluid/>            
            </Col>
            <Col xs={12} md={8} xl={8}>
                <div className="cake-info">
                    <p>{showCakeInfo.info}</p>
                    <p>{showCakeInfo.description}</p>
                    <p>{showCakeInfo.size}</p>
                </div>            
                <h2 className="mt-4">Sabores</h2>
            
                {emptyData 
                ? <p> Categoría sin productos :(</p>
                :        
            products.map((prd, i) => (
                <div key={i} className="row-cake mt-2">
                    <div className="cake-title">{prd.title}</div>
                    <div className="price">{prd.price}€</div>
                </div>

                
                ))}                
            
            
            </Col>

        
            
            </Row>
             }
             
            </Content>
       
        </>
    )
}



export default CakesList

const Content = styled(Container)`
//margin-top: 2rem;
margin-bottom: 2rem;

.cake-info {

}

p {
    color: #084c61;  
    font-size: 1.1rem;
    text-align: left;          
            
}

h2 {
    color: #084c61;
    font-size: 2rem;
    font-weight: 600;   
    text-align: center;
}


.row-cake {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cake-title {       
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    text-align: left; 
    color: #DB3934;
    font-weight: bold;
    font-size: 1.2rem;

    :first-letter{
    text-transform: uppercase;
    
}
}

.price {
    padding: 0rem 1rem 1rem;
    color: #084c61;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: right;
}

`
