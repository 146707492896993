import React, { useState, useEffect } from 'react'
import { showIngredients, listIngredients, addIngredient, deleteRecipeIngredient } from '../../../services/admin'
import { useParams } from 'react-router-dom'
import Loading from '../../../components/loading'
import { Col, Row, Form, Button, Modal, InputGroup, Card } from 'react-bootstrap'
import { BiTrash, BiSearchAlt2 } from 'react-icons/bi'
import { IoMdAdd } from 'react-icons/io'
import Swal from 'sweetalert2'
import styled from 'styled-components'

const Ingredients = () => {

  const [ingredientRecipe, setIngredientRecipe] = useState([])
  const [ingredientsList, setIngredientsList] = useState([])
  const [form, setForm] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [updateList, setUpdateList] = useState()
  const [showModal, setShowModal] = useState(false)
  const [query, setQuery] = useState("")
  const { _id } = useParams()

  useEffect(() => {
    let get = async () => {
      setIsLoading(true)
      setForm({
        ingredient: ""
      })
      const ing = await showIngredients(_id)
      const ingList = await listIngredients()
      setIngredientRecipe(ing.data)
      setIngredientsList(ingList.data)
      setUpdateList(false)
      setIsLoading(false)
    }
    get()

    return () => get = () => {
      //cleanup.
    }
  }, [updateList, _id])

  const sumCosts = ingredientRecipe.reduce((sum, value) => (typeof value.ingredientCostRecipe == "number" ? sum + value.ingredientCostRecipe : sum), 0)
  const calcRecomendedPrice = sumCosts * 3
  const totalCostDecimal = parseFloat(Math.round(sumCosts * 100) / 100).toFixed(2)
  const recomendedPrice = parseFloat(Math.round(calcRecomendedPrice * 100) / 100).toFixed(2)

  const handleShow = () => setShowModal(true)
  const handleClose = () => {
    setForm({})
    uncheck()
    setShowModal(false)
  }

  const handleChange = (attr) => {
    const { name, value } = attr.target
    setForm({
      ...form,
      product: _id,
      [name]: value
    })
  }
  const isSelected = () => form.ingredient !== ""
  const isFormValid = () => form.ingredient !== "" && form.qtyIngredient
  const uncheck = () => {
    let radio = document.querySelector('input[name="ingredient"]:checked')
    if(radio){
      radio.checked = false
    }
  }

  const submitIngredient = async (e) => {
    e.preventDefault()
    const message = (type, message) =>
      Swal.fire({
        toast: true,
        position: 'bottom',
        width: '20rem',
        icon: type || "success",
        title: message,
        showConfirmButton: false,
        timer: 2500,
        //background: bg,
        //iconColor: '#fff'       

      })
    setIsLoading(true)
    try {
      await addIngredient(form)
      message('success', `Ingrediente añadido con éxito`, '#a5dc86')
      uncheck()
      setForm({})
      setUpdateList(true)

    } catch (error) {
      message('error', `Ha ocurrido un error`, '#f27474')
      setIsLoading(false)
    }

  }

  const removeIngredient = async (ing) => {
    const message = (type, message) => Swal.fire({
      toast: true,
      position: 'bottom',
      width: '20rem',
      icon: type || '',
      title: message || '',
      showConfirmButton: false,
      timer: 2500
    })

    Swal.fire({
      title: `¿Deseas eliminar el ingrediente ${ing.ingredient} de la receta? `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Eliminar`,
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecipeIngredient(ing._id)
          .then(() => {
            setUpdateList(true)
            message('success', `Ingrediente eliminado con éxito.`)
          })
          .catch(() => message('error', `Ha ocurrido un error`))
      }
    })
  }

  return (
    <>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable
      size="lg"
      >
        <StyledModal>
          <Modal.Header>
            <Modal.Title>Seleccionar ingredientes</Modal.Title>

            <Col xs={12} md={12} xl={12} className="search-wrapper">
              <InputGroup>
                <InputGroup.Text><BiSearchAlt2 /></InputGroup.Text>
                <Form.Control
                  autoComplete="off"
                  id="search"
                  type="search"
                  placeholder="Buscar ingrediente"
                  value={query}
                  onChange={(e) => setQuery(e.target.value.toLowerCase())}
                />
              </InputGroup>
            </Col>

          </Modal.Header>
        </StyledModal>
        <Modal.Body>


          {ingredientsList
            .filter(ing => ing.name.toLowerCase().includes(query))
            .map((filtered, i) => (
              <StyledRadio key={i}>
                <input
                  type="radio"
                  id={filtered._id}
                  name="ingredient"
                  value={filtered._id}
                  onChange={handleChange}
                />
                <div className="wrapper-radio">
                  <label htmlFor={filtered._id}>{filtered.name} ({filtered.uom})</label>
                </div>
              </StyledRadio>
            ))}
        </Modal.Body>
        <StyledModal>
          <Modal.Footer>

            <Row className="d-flex">
              <Col xs={12} md={12} xl={12}>
                <Row>
                <Col xs={8} md={6} xl={6} className="mt-2 mb1">                  
                  <Form.Control
                  autoComplete="off"
                  type="number"
                  id="inlineFormInputName"
                  onChange={handleChange}
                  disabled={!isSelected()}
                  placeholder="Cantidad gr/ml/und"
                  name="qtyIngredient"
                  value={form.qtyIngredient || ""}
                  min="0"
                />      
                </Col>
                <Col xs={4} md={3} xl={3} className="mt-2 mb1">                  
                  <Button variant="success" onClick={submitIngredient} disabled={!isFormValid()}>Añadir</Button>                  
                </Col>
                <Col xs={12} md={3} xl={3} className="d-flex mt-2">
                  <Button className="btn" variant="secondary" onClick={handleClose}>
                    Cerrar
                  </Button>
                  </Col>
                </Row>                
              </Col>
            </Row>


          </Modal.Footer>
        </StyledModal>
      </Modal>

      <Content>
        <Card className="mb-3">
          <Card.Body className="card-total">
            <Row>
              <Col xs={12} md={8} xl={9}>
                <Col xs={12} md={12} xl={4}>
                  <div className="total">
                    Coste total receta: {totalCostDecimal}€
                  </div>
                </Col>
                <Col xs={12} md={12} xl={4}>
                  <div className="total">
                    PVP recomendado: {recomendedPrice}€
                  </div>
                </Col>
              </Col>
              <Col xs={12} md={4} xl={3} className="mt-2">
                <div className="div-btn">
                  <Button onClick={handleShow} className="btn-add"><IoMdAdd className="icon-btn" /> Añadir ingrediente</Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {isLoading
          ? <Loading />
          : ingredientRecipe.length !== 0
          ? ingredientRecipe.map((ing, i) => (

            <RowIngredient className="mb-3" key={i}>
              <Col xs={12} md={12} xl={12}>
                <div className="ingredient-title">
                  <div className="ingredient-qty">{ing.qtyIngredient}{ing.uom}</div>
                  <div className="ingredient-name">{ing.ingredient}</div>
                  <div className="actions">
                    <BiTrash className="icon" onClick={() => removeIngredient(ing)} />
                  </div>
                </div>
              </Col>
              <Col className="lines" xs={6} md={6} xl={6}>
                <p>Coste gr/ml/und: {ing.priceByQty.toFixed(4)}€</p>
              </Col>
              <Col xs={6} md={6} xl={6}>
                <p>Coste en receta: {ing.ingredientCostRecipe.toFixed(4)}€</p>
              </Col>
            </RowIngredient>
          ))
          : <p className="p-no-ingredients">Todavía no se han añadido los ingredientes para crear esta receta. Haz click en añadir ingrediente para empezar! ;)</p>}
      </Content>
    </>
  )

}

export default Ingredients

const StyledModal = styled.div`
.modal-header{
  background-color: #f8f8fa ;
  display: block;
  padding: 0rem;
  .modal-title {            
            color: #084c61;
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 0;   
            padding: 1rem 2rem 1rem 2rem;        
        }
        .search-wrapper{
          padding: 0 2rem 1rem 2rem;
        }
}
.modal-footer{
  display: block;
  background-color: #f8f8fa;
  padding-top: 0;

  .btn{
    width: 100%;
  }
}

`

const StyledRadio = styled.div`
input[type="radio"] {
  display: none;  
}

input[type="radio"]:checked + .wrapper-radio {
  background-color: #dee2e6;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    color: red;
    font-size: 1.2rem;
}

label {
  color: #084c61;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  width: 100%;

}

.wrapper-radio {
  border-radius: .25rem;
  //background-color: blue;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: all 0.15s ease-in-out 0s;
  
  :hover{
    background-color: #f8f8fa;
    border: 1px solid #dee2e6;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    transition: all 0.15s ease-in-out 0s;   

  }
}

`

const Content = styled.div`    

.icon-btn {
  color: #f8f8fa;
  font-size: 1.2rem;
  margin-bottom: .2rem;
}

.card-total {
  //background-color: #f8f8fa;
}

.total {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    //flex: none;   
}

.div-btn{
  text-align: right;
}
.btn-add{
  width: 100%;
}

.p-no-ingredients {
    color:#888888;    
    margin-bottom: 0px;
    text-align: center;
}

`

const RowIngredient = styled(Row)`

p {
    color:#888888;
    font-size: 1.1rem;
    padding-left: .4rem;
    margin-top: .4rem;
    margin-bottom: 0;

}
.ingredient-title{
    padding: .3rem;
    display:flex;    
    flex-direction: row;
    justify-content: space-between;
    //background-color: #dee2e6;       
    border-top: 1px solid #dee2e6;
    
}

.ingredient-name {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ingredient-qty {
    color: #084c61;
    font-size: 1.2rem;
    font-weight: 600;
    flex: none;
    width: 80px;
   
}

.actions {
    flex: none;
}
.icon {
    color: #084c61;
    font-size: 1.2rem;
    margin-left: 7px;
    cursor: pointer;
    :hover {
        color: #db3934;
    }
}


`